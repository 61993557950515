import React, { useState, useEffect } from "react";
import { Button, Label, Input } from "reactstrap";
import Hader from "../../Component/Hader";
import Footer from "../../Component/Footer";
import BannerTwo from "../../Component/BannerTwo";
import { ErrorMessage, Formik } from "formik";
import { Card, Col, Container, Form, Row } from "react-bootstrap";
import ProfileSideBar from "../../Component/profileSideBar";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { getUsers, updateUsers } from "../../functions/Auth/Users";
import { ChangePasswordUser } from "../../functions/Auth/Users";
import { ToastContainer, toast } from "react-toastify";
import Cart from "../../Component/Cart";
import { Puff } from "react-loader-spinner";
import "../../assets/css/profile.css";
import MobileHeader from "../../Component/MobileHeader";

const initialState = {
  firstName: "",
  lastName: "",
  contactNo: "",
  email: "",
  // password: "",
};

const initialStatePasswords = {
  password: "",
  newPassword: "",
  ConfirmPassword: "",
  userId: localStorage.getItem("NicksCoUser"),
};

const MyAccount = () => {
  const [status, setStatus] = useState(false);
  const [userData, setUserData] = useState([]);
  const user = localStorage.getItem("NicksCoUser");
  const [values, setValues] = useState(initialState);
  const {
    firstName,
    lastName,
    contactNo,
    email,
    // password
  } = values;

  const [valuesPassword, setValuesPassword] = useState(initialStatePasswords);
  const { password, newPassword, ConfirmPassword, userId } = valuesPassword;

  const [errP, setErrP] = useState(false);
  const [errNP, setErrNP] = useState(false);
  const [errCP, setErrCP] = useState(false);

  const validateValues = (valuesPassword) => {
    const errors = {};

    if (valuesPassword.password === "") {
      errors.password = "Password is required!";
      setErrP(true);
    }
    if (valuesPassword.password !== "") {
      setErrP(false);
    }

    if (valuesPassword.newPassword === "") {
      errors.newPassword = "User Password is required!";
      setErrNP(true);
    }
    if (valuesPassword.newPassword !== "") {
      setErrNP(false);
    }

    if (valuesPassword.ConfirmPassword === "") {
      errors.ConfirmPassword = "Confirm Password is required!";
      setErrCP(true);
    }

    if (valuesPassword.ConfirmPassword) {
      setErrCP(false);
    }

    //    DATE OF EXPIRY

    return errors;
  };

  const [formErrorsPass, setFormErrorsPass] = useState({});
  const [isSubmitPass, setIsSubmitPass] = useState(false);
  // const [errMessage, setErrMessage] = useState(false);
  const handleChangePassword = (e) => {
    e.preventDefault();
    console.log("valuesPassword", valuesPassword);
    setIsSubmitPass(true);
    setFormErrorsPass(validateValues(valuesPassword));
    // setValuesPassword({
    //   ...valuesPassword,
    //   userId: localStorage.getItem("NicksCoUser"),
    // });
    ChangePasswordUser({
      ...valuesPassword,
      userId: localStorage.getItem("NicksCoUser"),
    })
      .then((res) => {
        if (res.data.isOk) {
          console.log(" res updates", res.data);
          setValuesPassword(initialStatePasswords);
          // setErrMessage(true);
          // toast.error("Password Updated Successfully");
        } else {
          if (res.data.field === 1) {
            setErrCP(true);
            setFormErrorsPass({ ConfirmPassword: res.data.message });
          } else if (res.data.field === 2) {
            setErrP(true);
            setFormErrorsPass({ password: res.data.message });
          }
          console.log(" res updates", res.data.message);
        }
      })
      .catch((err) => {
        console.log("error in change password", err);
      });
    // setErrMessage(false);
  };

  const validClasspassword = errP ? "form-control is-invalid" : "form-control";
  const validClassNewPassword = errNP
    ? "form-control is-invalid"
    : "form-control";

  const validClassConfirmPassword = errCP
    ? "form-control is-invalid"
    : "form-control";

  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  useEffect(() => {
    console.log(formErrors);
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      console.log("no errors");
    }
  }, [formErrors, isSubmit]);

  const loadUser = () => {
    getUsers(user).then((res) => {
      console.log("user", res.data);
      const response = res.data;
      setValues({
        ...values,
        firstName: response.firstName,
        lastName: response.lastName,
        email: response.email,
        contactNo: response.contactNo,
      });
      console.log("values", values);
      // setUserData([res.data]);
    });
  };

  const [errFN, setErrFN] = useState(false);
  const [errLN, setErrLN] = useState(false);
  const [errEM, setErrEM] = useState(false);
  const [errCN, setErrCN] = useState(false);
  // const [errPA, setErrPA] = useState(false);

  const validate = (values) => {
    const errors = {};
    const phoneRegex = /^\d{10}$/;

    if (values.firstName === "") {
      errors.firstName = "First Name is required!";
      setErrFN(true);
    }
    if (values.firstName !== "") {
      setErrFN(false);
    }

    if (values.lastName === "") {
      errors.lastName = "Last Name is required!";
      setErrLN(true);
    }
    if (values.lastName !== "") {
      setErrLN(false);
    }

    if (values.email === "") {
      errors.email = "email is required!";
      setErrEM(true);
    }
    if (values.email !== "") {
      setErrEM(false);
    }

    if (values.contactNo === "") {
      console.log("empty");
      errors.contactNo = "Contact Number is required!";
      setErrCN(true);
    }
    // if (values.contactNo !== "") {
    //   setErrCN(false);
    // }
    else if (!phoneRegex.test(values.contactNo)) {
      errors.contactNo = "This is not a valid Contact Number";
      setErrCN(true);
    }

    return errors;
  };

  const validClassFN =
    errFN && isSubmit ? "form-control is-invalid" : "form-control";

  const validClassLN =
    errLN && isSubmit ? "form-control is-invalid" : "form-control";

  const validClassEM =
    errEM && isSubmit ? "form-control is-invalid" : "form-control";
  const validClassCN =
    errCN && isSubmit ? "form-control is-invalid" : "form-control";

  const handleUpdateChanges = (e) => {
    e.preventDefault();
    let erros = validate(values);
    setFormErrors(erros);
    setIsSubmit(true);

    if (Object.keys(erros).length === 0) {
      updateUsers(user, values)
        .then((res) => {
          console.log("res in update", res);
          // setValues(initialState);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleChangePasswords = (e) => {
    setValuesPassword({ ...valuesPassword, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    loadUser();
  }, []);
  // const handleSubmit = (values, { setSubmitting }) => {
  //   setTimeout(() => {
  //     alert(JSON.stringify(values, null, 2));
  //     setSubmitting(false);
  //   }, 400);
  // };
  // const loginSchema = Yup.object().shape({
  //   password: Yup.string()
  //     .min(8, "Too Short!")
  //     .max(50, "Too Long!")
  //     .required("Required"),
  //   email: Yup.string().email("Invalid email").required("Required"),
  // });
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const delay = 1000;
    setTimeout(() => {
      setIsLoading(false);
    }, delay);
  }, []);
  return (
    <React.Fragment >
      {isLoading ? (
        // Loader component while loading
        <div className="loader-container">
          <Puff
            color="#c7a17a"
            height={50}
            width={50}
            timeout={0} // 0 means no timeout, loader will be displayed until setIsLoading(false) is called
          />
        </div>
      ) : (
        <>
          <ToastContainer />
          <Cart />
          <div>
        <Hader Color="#000" />
            <MobileHeader />
          </div>
          <BannerTwo zIndex="-100" Title="My Account" subTitle="My Account" />
          <Container className="sec_ptb_120 ">
            <Row>
              <Col lg="3" md={4}>
                <ProfileSideBar />
              </Col>
              <Col lg="9" md={8}>
                <Card className="myProfileCard">
                  {status ? (
                    <h6>Update Password</h6>
                  ) : (
                    <h6>
                      Welcome!, - Please Update Your Account Information Below.
                    </h6>
                  )}

                  <hr />

                  {/* <Formik
                    initialValues={{ email: "", password: "" }}
                    validationSchema={loginSchema}
                    onSubmit={handleSubmit}
                  >
                    {({ isSubmitting }) => {
                      return ( */}
                  <Form>
                    {status ? (
                      <div className="mainDivChangPassword">
                        {/* <h5 className="mb-3 mt-4">Change Password</h5>
                              <hr /> */}
                        <Row>
                          <Col lg="6">
                            <label className="mb-2">Enter Old Password</label>
                            <br />
                            <input
                              style={{
                                borderRadius: "0px",
                                padding: "10px",
                              }}
                              // placeholder="Enter Old Password"
                              // className="inputLableTitle"
                              // type="password"
                              // name="password"
                              className={validClasspassword}
                              placeholder="Enter password"
                              type="password"
                              name="password"
                              value={password}
                              onChange={handleChangePasswords}
                            />

                            {isSubmitPass && (
                              <p className="text-danger">
                                {formErrorsPass.password}
                              </p>
                            )}
                          </Col>
                          <Col lg="6">
                            <label className="mb-2">Enter New Password</label>
                            <br />
                            <input
                              style={{
                                borderRadius: "0px",
                                padding: "10px",
                              }}
                              className={validClassNewPassword}
                              placeholder="Enter password"
                              type="password"
                              name="newPassword"
                              value={newPassword}
                              onChange={handleChangePasswords}
                            />
                            {isSubmitPass && (
                              <p className="text-danger">
                                {formErrorsPass.newPassword}
                              </p>
                            )}
                          </Col>
                          <Col lg="6">
                            <label className="mb-2">Confirm Password</label>
                            <br />
                            <input
                              style={{
                                // width: "100%",

                                borderRadius: "0px",
                                padding: "10px",
                              }}
                              className={validClassConfirmPassword}
                              placeholder="Enter password"
                              type="password"
                              name="ConfirmPassword"
                              value={ConfirmPassword}
                              onChange={handleChangePasswords}
                            />
                            {isSubmitPass && (
                              <p className="text-danger">
                                {formErrorsPass.ConfirmPassword}
                              </p>
                            )}
                          </Col>
                          <div
                            className="myProfileBtnDiv"
                            style={{ marginTop: "30px" }}
                          >
                            {/* {errMessage ? (
                              <h4>Password Updated Sucessfully</h4>
                            ) : null} */}
                            <button
                              className="btn btn-secondary text-uppercase addBtn"
                              onClick={() => setStatus(!status)}
                              type="button"
                            >
                              Cancel
                            </button>
                            <button
                              className="btn btn-dark text-uppercase addBtn  "
                              onClick={handleChangePassword}
                              type="button"
                              style={{ marginLeft: "10px" }}
                            >
                              Update
                            </button>
                          </div>
                        </Row>
                      </div>
                    ) : (
                      <Row>
                        <Col lg={6}>
                          <label className="mb-2">First Name</label>
                          <br />
                          <input
                            style={{
                              borderRadius: "0px",
                              padding: "10px",
                            }}
                            className={validClassFN}
                            placeholder="Enter First Name"
                            type="text"
                            // name="email"
                            name="firstName"
                            value={firstName}
                            onChange={handleChange}
                          />

                          {isSubmit && (
                            <p className="text-danger">
                              {formErrors.firstName}
                            </p>
                          )}
                        </Col>
                        <Col lg={6}>
                          <label className="mb-2">Last Name</label>
                          <br />

                          <input
                            style={{
                              borderRadius: "0px",
                              padding: "10px",
                            }}
                            className={validClassLN}
                            placeholder="Enter Last Name"
                            type="text"
                            // name="email"
                            name="lastName"
                            value={lastName}
                            onChange={handleChange}
                          />

                          {isSubmit && (
                            <p className="text-danger">{formErrors.lastName}</p>
                          )}
                        </Col>
                        <Col lg={6}>
                          <label className="mb-2">Email Id</label>
                          <br />

                          <input
                            style={{
                              borderRadius: "0px",
                              padding: "10px",
                            }}
                            className={validClassEM}
                            placeholder="Enter Mail Id"
                            type="email"
                            name="email"
                            value={email}
                            onChange={handleChange}
                          />
                          {isSubmit && (
                            <p className="text-danger">{formErrors.email}</p>
                          )}
                        </Col>
                        <Col lg={6}>
                          <label className="mb-2">Contact Number</label>
                          <br />

                          <input
                            style={{
                              borderRadius: "0px",
                              padding: "10px",
                              // width: "100%",
                              // padding: "5px",
                              // border: "1px solid #c7a17a",
                              // marginBottom: " 10px",
                            }}
                            className={validClassCN}
                            placeholder="Enter Contact Number"
                            type="number"
                            name="contactNo"
                            value={contactNo}
                            onChange={handleChange}
                          />
                          {isSubmit && (
                            <p className="text-danger">
                              {formErrors.contactNo}
                            </p>
                          )}
                        </Col>
                        <div
                          className="myProfileBtnDiv"
                          style={{ marginTop: "30px" }}
                        >
                          <button
                            className="btn text-uppercase addBtn"
                            type="submit"
                            onClick={handleUpdateChanges}
                          >
                            Save Change
                          </button>
                          <button
                            className="btn  text-uppercase addBtn"
                            onClick={() => setStatus(!status)}
                            type="button"
                          >
                            Update Password?
                          </button>
                        </div>
                      </Row>
                    )}
                  </Form>
                  {/* );
                    }}
                  </Formik> */}
                </Card>
              </Col>
            </Row>
          </Container>
          <Footer />
        </>
      )}
    </React.Fragment>
  );
};

export default MyAccount;
