import React, { useEffect, useState, useContext } from "react";
import Hader from "../Component/Hader";
// import MobileHader from "../Component/MobileHader";
import { MyContext } from "../MyContext";

import Footer from "../Component/Footer";
import { Card, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import BradeCrum from "../Component/BradeCrum";
import "../assets/css/thankyou.css";
import { getLatestOrderByUser } from "../functions/Orders/Order";
import { getProductVariantsInfoInCart } from "../functions/ProductDetails/ProductDetails";
import { listCompanynDetails } from "../functions/CompanyDetails/CompanyDetails";
import { listLocationDetails } from "../functions/Location/companyLocation";
import { getCountry, getState } from "../functions/Location/Location";
import { listDeliveryOption } from "../functions/DeliveryOptions/DeliveryOption";
import moment from "moment-timezone";
import { downloadInvoicePDF } from "../functions/Orders/Order";
import { Puff, TailSpin } from "react-loader-spinner";
import MobileHeader from "../Component/MobileHeader";
import { CircularProgress } from "@mui/material";
import { getUsers } from "../functions/Auth/Users";

const ThankYou = () => {
  const [orderData, setOrderData] = useState([]);
  const [orderDetails, setOrderDetails] = useState([]);

  const { prodVariantsData, setProdVariantsData } = useContext(MyContext);
  const [deliveryOptionsData, setDeliveryOptionsData] = useState([]);

  const [maile, setMaile] = useState("");

  useEffect(() => {
    getUsers(userId)
      .then((res1) => {
        console.log("user", res1.data);
        setMaile(res1.data.email);
      });
  }, []);


  const loadDeliveryOptions = () => {
    listDeliveryOption().then((res) => {
      console.log("all delivery add", res.data);
      setDeliveryOptionsData(res.data);
    });
  };
  const userId = localStorage.getItem("NicksCoUser");

  const [companyData, setCompanyData] = useState([]);

  const loadCompanyDetails = () => {
    listLocationDetails().then((res) => {
      console.log("com", res.data);
      setCompanyData(res.data);
    });
  };

  useEffect(() => {
    loadCompanyDetails();
    loadDeliveryOptions();
  }, []);

  const [buttonLoadingStates, setButtonLoadingStates] = useState(false);

  const downloadPDF = (orderId, randomOrderId, index) => {
    // const updatedButtonLoadingStates = [...buttonLoadingStates];
    // updatedButtonLoadingStates[index] = true;
    setButtonLoadingStates(true);

    console.log("orderId in downloadPDF", orderId);
    downloadInvoicePDF(orderId)
      .then((response) => {
        // Extract the data from the response Blob
        const reader = new FileReader();
        reader.onload = () => {
          const data = reader.result;
          const blob = new Blob([data], {
            type: "application/pdf",
          });
          console.log("Blob size:", blob.size);
          const a = document.createElement("a");
          a.href = URL.createObjectURL(blob);
          a.download = `Invoice_${randomOrderId}.pdf`;
          a.click();

          // const updatedButtonLoadingStates = [...buttonLoadingStates];
          // updatedButtonLoadingStates[index] = false;
          setButtonLoadingStates(false);
        };

        reader.readAsArrayBuffer(response.data);
      })
      .catch((error) => {
        console.log("error in downloadInvoicePDF", error);
        // const updatedButtonLoadingStates = [...buttonLoadingStates];
        // updatedButtonLoadingStates[index] = false;
        setButtonLoadingStates(false);
      });
  };

  const loadOrderData = async () => {
    getLatestOrderByUser(userId)
      .then(async (res) => {
        console.log("res in order data", res.data[0]);
        setOrderData(res.data[0]);
        setOrderDetails(res.data[0].orderDetails);

        const resp = res.data[0].orderDetails;
        const promises = resp.map((item) => {
          if (item.productVariantsId !== null)
            return getProductVariantsInfoInCart(item.productVariantsId).then(
              (res1) => {
                // console.log("product variants info", res1.data[0]);
                return res1.data[0]; // Return the object directly
              }
            );
          else return null; // Explicitly return null for consistency
        });

        const variantDataArray = await Promise.all(promises.filter(Boolean));

        setProdVariantsData(variantDataArray);
      })
      .catch((error) => {
        console.error("Error loading order data:", error);
      });
  };

  const [countryName, setCountryName] = useState("");

  const [stateName, setStateName] = useState("");

  const [countryNameBA, setCountryNameBA] = useState("");

  const [stateNameBA, setStateNameBA] = useState("");

  useEffect(() => {
    getCountry(orderData?.shippingDetails?.countryId).then((res) => {
      setCountryName(res.data.CountryName);
    });

    getCountry(orderData?.billingAddress?.countryId).then((res) => {
      setCountryNameBA(res.data.CountryName);
    });
  }, [
    orderData?.shippingDetails?.countryId,
    orderData?.billingAddress?.countryId,
  ]);

  useEffect(() => {
    getState(orderData?.shippingDetails?.stateId).then((res) => {
      setStateName(res.data.StateName);
    });
    getState(orderData?.billingAddress?.stateId).then((res) => {
      setStateNameBA(res.data.StateName);
    });
  }, [orderData?.shippingDetails?.stateId, orderData?.billingAddress?.stateId]);

  useEffect(() => {
    loadOrderData();
  }, []);

  if (!orderData) {
    return null;
  } // Render nothing if orderData is not available
  else {
    return (
      <React.Fragment>
        <Hader Color="#000" />
        <MobileHeader />
        {/* <BradeCrum /> */}

        {/* <MobileHader /> */}
        <hr className="mt-5"></hr>

        <Row className="m-5 d-flex justify-content-center">
          <Col lg={9} sm={12}>
            <div className="d-flex flex-column align-items-center">
              <h1 className="mt-5">
                Thank you, {orderData.userFirstName} {orderData.userLastName}
              </h1>
              <p>confirmation email sent to {maile}</p>
              <p>
                Your Order Number: <strong>{orderData.randomOrderId}</strong>
              </p>
            </div>

            <div className="card p-5" style={{ overflowX: "scroll" }}>
              <div className="order-card-header">
                <h5>Order Summery </h5>
              </div>
              <hr></hr>

              <table>
                <tbody>
                  {orderDetails &&
                    orderDetails.map((item) => {
                      const product = orderData?.productDetails.find(
                        (pd) => pd._id === item.productId
                      );
                      if (!product) return null; // Skip if product not found

                      let productAmount = parseFloat(
                        parseFloat(item.amount) / parseFloat(item.quantity)
                      );
                      return (
                        <React.Fragment>
                          <tr
                            key={item._id}
                            style={{ borderBottom: "1px solid grey" }}
                          >
                            <td>
                              <img
                                // src={coffee}
                                src={`${process.env.REACT_APP_API_URL_COFFEE}/${product.productImage}`}
                                width="150px"
                                alt="product"
                                className="order-product-image"
                                style={{
                                  aspectRatio: "1/2",
                                  objectFit: "contain",
                                }}
                              />{" "}
                            </td>
                            <td
                              className="order-summery-name-price"
                              style={{ verticalAlign: "top" }}
                            >
                              <h6>{product.productName} </h6>
                              <p>$ {parseFloat(productAmount).toFixed(2)} </p>

                              {item.isSubs && (
                                <p style={{ fontSize: "15px" }}>
                                  <b>Subscribed: </b>
                                  {
                                    orderData?.subsDetails?.find(
                                      (subs) => subs._id === item.subsId
                                    )?.title
                                  }
                                </p>
                              )}

                              {/* <p>grind type : Fine</p> */}
                              {prodVariantsData.find(
                                (variant) =>
                                  variant._id === item.productVariantsId
                              ) && (
                                  <p style={{ fontSize: "15px" }}>
                                    {prodVariantsData
                                      .find(
                                        (variant) =>
                                          variant._id === item.productVariantsId
                                      )
                                      .parameters.map((param, index) => (
                                        <React.Fragment key={index}>
                                          <b>{param.parameterName}</b>:{" "}
                                          {param.parameterValue}
                                          <br />
                                        </React.Fragment>
                                      ))}
                                  </p>
                                )}

                              {item.productComment ? (
                                <p>
                                  <b>Product Note: </b>
                                  {item.productComment}{" "}
                                </p>
                              ) : null}
                            </td>
                            <td
                              className="order-summery-name-price"
                              style={{ verticalAlign: "top", width: "150px" }}
                            >
                              <p style={{ fontSize: "18px" }}>
                                Qty : {item.quantity}
                                {/* / {product.basePrice} */}
                              </p>
                            </td>
                            <td
                              className="order-summery-name-price"
                              style={{ verticalAlign: "top" }}
                            >
                              <p style={{ fontSize: "18px" }}>
                                ${parseFloat(item.amount).toFixed(2)}
                              </p>
                            </td>
                          </tr>
                        </React.Fragment>
                      );
                    })}

                  {orderData.orderComment && (
                    <tr>
                      <td className="pd-top-50 txt-start">
                        <p>
                          <span>
                            <b>
                              <i>Special Note</i>
                            </b>
                          </span>
                          : <i>{orderData.orderComment}</i>
                        </p>
                      </td>
                    </tr>
                  )}
                  {orderData.deliveryData && (
                    <tr>
                      <td className="pd-top-50 txt-start">
                        <p>
                          <span>
                            <b>
                              {deliveryOptionsData.find(
                                (item) =>
                                  item._id ===
                                  orderData.deliveryData.deliveryTypeId
                              ) ? (
                                <>
                                  <b>
                                    {
                                      deliveryOptionsData.find(
                                        (item) =>
                                          item._id ===
                                          orderData.deliveryData.deliveryTypeId
                                      )?.DeliveryType
                                    }
                                  </b>
                                  :{" "}
                                  <span>
                                    {moment(
                                      orderData.deliveryData.deliveryTime
                                    ).format("DD-MM-YYYY hh:mm a")}
                                  </span>
                                </>
                              ) : null}
                            </b>
                          </span>
                        </p>
                      </td>
                    </tr>
                  )}

                  <tr>
                    <td></td>
                    <td></td>
                    <td className="pd-top-50 txt-start">
                      <p>Subtotal</p>
                    </td>
                    <td className="pd-top-50 txt-end">
                      <p>${parseFloat(orderData.subTotal).toFixed(2)}</p>
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td className="txt-start">
                      <p>PromoCode</p>
                    </td>
                    <td className="txt-end">
                      {orderData.promoCode ? (
                        <p>{orderData.promoCode}</p>
                      ) : null}
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td className="txt-start">
                      <p>Discount</p>
                    </td>
                    <td className="txt-end">
                      {orderData.discount ? (
                        <p>- ${orderData.discount.toFixed(2)}</p>
                      ) : (
                        <p>$0.00</p>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td className="txt-start">
                      <p>Delivery</p>
                    </td>
                    {/* {orderData.isShippingType ? ( */}
                    <td className="txt-end">
                      <p>${parseFloat(orderData.shippingCharge).toFixed(2)}</p>
                    </td>
                  </tr>

                  <tr>
                    <td></td>
                    <td></td>
                    <td className="txt-start">
                      <p>Tip</p>
                    </td>
                    {/* {orderData.isShippingType ? ( */}
                    <td className="txt-end">
                      {orderData.tip ? (
                        <p>{orderData.tip}</p>
                      ) : (
                        <p>$0.00</p>
                      )}
                    </td>
                  </tr>
                  {/* <tr>
                    <td></td>
                    <td></td>
                    <td className="txt-start">
                      <p>Sales Tax</p>
                    </td>
                    <td className="txt-end">
                      <p>$0.00</p>
                    </td>
                  </tr> */}
                  <tr>
                    <td></td>
                    <td></td>
                    <td className="txt-start td-bold">
                      <p>Total</p>
                    </td>
                    <td className="txt-end td-bold">
                      <p>${parseFloat(orderData.totalAmount).toFixed(2)}</p>
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td className="txt-start mt-1rem">
                      <p>Paid</p>
                    </td>
                    <td className="txt-end mt-1rem">
                      <p>${parseFloat(orderData.totalAmount).toFixed(2)}</p>
                    </td>
                  </tr>
                  {/* <tr>
                    <td></td>
                    <td></td>
                    <td className="txt-start">
                      <p>Due Later</p>
                    </td>
                    <td className="txt-end">
                      <p>${parseFloat(orderData.totalAmount).toFixed(2)}</p>
                    </td>
                    <td></td>
                  </tr> */}
                  <tr>
                    <td style={{ width: "30%" }}></td>
                    <td style={{ width: "30%" }}></td>
                    <td
                      style={{
                        width: "40%",
                        textAlign: "right",
                      }}
                    >
                      <button
                        onClick={() => {
                          downloadPDF(orderData._id, orderData.randomOrderId);
                        }}
                        style={{ marginTop: "20px" }}
                        className="btn btn_primary text-uppercase invoiceBtn"
                      >
                        {buttonLoadingStates ? (
                          <div>
                            {/* <TailSpin
                              visible={true}
                              height="20"
                              width="100"
                              color="red"
                              ariaLabel="tail-spin-loading"
                              radius="2"
                              wrapperStyle={{}}
                              wrapperClass=""
                            /> */}
                            <CircularProgress
                              size={20}
                              sx={{ color: "white" }}
                              className="hello"
                            />
                          </div>
                        ) : (
                          <span>Get Invoice</span>
                        )}
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Col>
          <Col lg={9}>
            <div className="card mt-3">
              <div className="row">
                <div className="col-sm-4">
                  <div className="card-body p-3 mb-3">

                    {orderData.shippingDetails ? (
                      <p>
                        <h5> Delivery Address</h5>
                        {/* <br/> */}
                        {orderData?.shippingDetails?.addressLine1}
                        <br></br>
                        {/* {orderData?.shippingDetails?.addressLine2} */}
                        {/* <br></br> */}
                        {orderData?.shippingDetails?.city}
                        <br></br>
                        {stateName}
                        <br></br>
                        {orderData?.shippingDetails?.zipCode} <br></br>
                        {countryName} <br></br>
                      </p>
                    ) : (
                      <>
                        {orderData?.deliveryData?.deliveryAddress && (
                          <div>
                            {companyData
                              .filter(
                                (company) =>
                                  company._id ===
                                  orderData?.deliveryData?.deliveryAddress
                              )
                              .map((company) => (
                                <div key={company._id}>
                                  <p>
                                    <h5>Pickup From</h5>
                                    {/* <br/> */}
                                    {company.Address}
                                    <br />
                                    {company.cityName}
                                    <br />
                                    {company.stateName}
                                    <br />
                                    {company.countryName} <br />
                                  </p>
                                </div>
                              ))}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="card-body p-3 mb-3">
                    <h5>Billing Address</h5>
                    {/* <p>456 Elm St</p>
                    <p>City: Your City</p>
                    <p>Country: Your Country</p> */}
                    {orderData?.billingAddress?.addressLine1}
                    <br></br>
                    {/* {orderData?.billingAddress?.addressLine2}
                    <br></br> */}
                    {orderData?.billingAddress?.city}
                    <br></br>
                    {stateNameBA}
                    <br></br>
                    {orderData?.billingAddress?.zipCode} <br></br>
                    {countryNameBA} <br></br>
                  </div>
                </div>
                <div hidden className="col-sm-4">
                  <div className="card-body p-3 mb-3">
                    <h5>Payment Method</h5>
                    <p>Using Paypal</p>
                    {/* <p>Card Number: ************1234</p>
                    <p>Expiry Date: 12/25</p>
                    <p>Security Code: ***</p> */}
                  </div>
                </div>
              </div>
            </div>
            <p style={{ textAlign: "center", paddingTop: "20px" }}>
              <u>
                {" "}
                <Link to="/" className="btn_text m-1">
                  <span>Continue Browsing</span>
                </Link>
              </u>
            </p>
          </Col>
        </Row>
        <Footer />
      </React.Fragment>
    );
  }
};

export default ThankYou;
