import React, { useEffect, useState } from "react";
import { Navigate, useLocation } from 'react-router-dom'
import Hader from "../../Component/Hader";
import { FaCoffee, FaRegUserCircle, FaAddressBook } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import {
  Input,
  Label,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { listState, listCountry } from "../../functions/Location/Location";
import Footer from "../../Component/Footer";
import BannerTwo from "../../Component/BannerTwo";
import {
  getAllShippingAddressofUser,
  createUserShippingAddress,
  getUserShippingAddress,
  updateUserShippingAddress,
  removeUserShippingAddress,
} from "../../functions/Auth/UserShippings";
import { getUsers } from "../../functions/Auth/Users";
import { updateDefaultAddress } from "../../functions/Auth/UserShippings";
import { Link } from "react-router-dom";
import ProfileSideBar from "../../Component/profileSideBar";
import Cart from "../../Component/Cart";
import { Puff } from "react-loader-spinner";
import "../../assets/css/profile.css";
import MobileHeader from "../../Component/MobileHeader";

let initialState = {
  userId: localStorage.getItem("NicksCoUser"),
  firstName: "",
  lastName: "",
  contactNo: "",
  companyName: "",
  city: "",
  stateId: "",
  countryId: "",
  addressLine1: "",
  addressLine2: "",
  zipCode: "",
  isBillingSame: false,
  billingId: null,
  IsActive: true,
};
const MyAddress = () => {
  const [ShippingAddressData, setShippingAddressData] = useState([]);
  const [defaultAdd, setDefaultAdd] = useState(false);
  const [values, setValues] = useState(initialState);
  const [remove_id, setRemove_id] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isDisplayError, setIsDIsplayError] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [_id, set_Id] = useState("");

  const navigate = useNavigate();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  let isFirstTime = queryParams.get('isfirsttime') || 0;

  let {
    userId,
    firstName,
    lastName,
    contactNo,
    companyName,
    city,
    stateId,
    countryId,
    addressLine1,
    addressLine2,
    zipCode,
    isBillingSame,
    billingId,
    IsActive,
  } = values;

  useEffect(() => {
    loadShippingAddress();
    if (isFirstTime === 1) {
      tog_list();
    }
  }, []);

  useEffect(() => {
    loadCountries();
    loadStates();
  }, []);

  const loadCountries = () => {
    listCountry().then((res) => {
      setCountries(res.data);
    });
  };

  const loadStates = () => {
    listState().then((res) => {
      setStates(res.data);
    });
  };

  const [checkedAddresses, setCheckedAddresses] = useState([]);
  const [userData, setUserData] = useState([]);

  const loadShippingAddress = () => {
    const userId = localStorage.getItem("NicksCoUser");
    if (userId === null) {
      return;
    }
    getAllShippingAddressofUser(userId).then((res) => {
      const response = res.data;
      setShippingAddressData(res.data);

      getUsers(userId)
        .then((res1) => {
          console.log("user", res1.data);
          setUserData(res1.data);
          const data = res1.data;
          initialState.firstName = res1.data.firstName;
          initialState.lastName = res1.data.lastName;
          initialState.countryId = "65b9e7c8a988700c5918f830";
          initialState.contactNo = res1.data.contactNo;
          console.log("data", initialState);


          const defaultShippingAddressId =
            data.shippingAddress[data.defaultShippingAddress];

          if (defaultShippingAddressId !== undefined) {
            setCheckedAddresses([defaultShippingAddressId]);
          }

          console.log("defaultShippingAddressId", defaultShippingAddressId);


        })
        .catch((err) => {
          console.log("error in user", err);
        });

      if (res.data.length === 0 || (isFirstTime === 1)) {
        tog_list();
      }

     // if (res.data.length === 1) {
     //   navigate("/checkout");
    //  }
      // setCheckedAddresses([
      //   response.shippingAddress[response.defaultShippingAddress],
      // ]);
    });
  };


  const handleChangeOption = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
    console.log(values);
  };

  const handleTog_edit = (_id) => {
    setmodal_list(!modal_list);
    setIsSubmit(false);
    setIsDIsplayError(true);
    setIsEdit(true);
    set_Id(_id);
    getUserShippingAddress(_id)
      .then((res) => {
        console.log(res.data);
        const response = res.data;
        setValues({
          ...values,
          userId: response.userId,
          firstName: response.firstName,
          lastName: response.lastName,
          contactNo: response.contactNo,
          companyName: response.companyName,
          city: response.city,
          stateId: response.stateId,
          countryId: response.countryId,
          addressLine1: response.addressLine1,
          addressLine2: response.addressLine2,
          zipCode: response.zipCode,
          IsActive: response.IsActive,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    let erros = validate(values);
    setFormErrors(erros);
    setIsSubmit(true);
    setIsDIsplayError(true);

    if (Object.keys(erros).length === 0) {
      updateUserShippingAddress(_id, values)
        .then((res) => {
          setmodal_list(!modal_list);
          setValues(initialState);
          setIsDIsplayError(false);
          loadShippingAddress();
          navigator("/profile/shippingaddress");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleSubmit = (e) => {
    values.userId = localStorage.getItem("NicksCoUser");
    e.preventDefault();
    setFormErrors({});
    let erros = validate(values);
    console.log("erros", erros);
    setFormErrors(erros);
    setIsSubmit(true);
    setIsDIsplayError(true);

    if (Object.keys(erros).length === 0) {
      createUserShippingAddress(values)
        .then((res) => {
          console.log("res", res);
          setmodal_list(!modal_list);
          setValues(initialState);
          setIsDIsplayError(false);
          setIsSubmit(false);

          handlecheck2(res.data._id);

          loadShippingAddress();

          console.log("isFirstTime", isFirstTime);

          if (isFirstTime === "1") {

            navigate("/checkout");

          };

        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleDelete = (e) => {
    e.preventDefault();
    removeUserShippingAddress(remove_id)
      .then((res) => {
        setmodal_delete(!modal_delete);
        loadShippingAddress();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [modal_delete, setmodal_delete] = useState(false);

  const tog_delete = (_id) => {
    setmodal_delete(!modal_delete);
    setRemove_id(_id);
  };

  const [modal_list, setmodal_list] = useState(false);
  const tog_list = () => {
    setmodal_list(!modal_list);
    setValues(initialState);

  };

  const handlecheck = (e, addressId) => {
    setDefaultAdd(e.target.checked);
    console.log("addressId", addressId);
    if (e.target.checked) {
      setCheckedAddresses([addressId]);

      // setCheckedAddresses((prevState) => [...prevState, addressId]); // Add addressId to the array
      updateDefaultAddress(userId, addressId)
        .then((res) => {
          console.log("res in update default address", res.data);
        })
        .catch((err) => {
          console.log("error in default address", err);
        });
    } else {
      setCheckedAddresses([]);

      // setCheckedAddresses((prevState) =>
      //   prevState.filter((id) => id !== addressId)
      // ); // Remove addressId from the array
    }
  };

  const handlecheck2 = (addressId) => {

    setCheckedAddresses([addressId]);

    // setCheckedAddresses((prevState) => [...prevState, addressId]); // Add addressId to the array
    updateDefaultAddress(userId, addressId)
      .then((res) => {
        console.log("res in update default address 2", res.data);
      })
      .catch((err) => {
        console.log("error in default address 2", err);
      });
    ; // Remove addressId from the array
  }

  const [errFN, setErrFN] = useState(false);
  const [errCN, setErrCN] = useState(false);
  const [errCID, setErrCID] = useState(false);
  const [errSID, setErrSID] = useState(false);
  const [errCI, setErrCI] = useState(false);
  const [errAL1, setErrAL1] = useState(false);

  const [errZP, setErrZP] = useState(false);

  const validate = (values) => {
    const errors = {};
    //const phone = /^\d{10}$/;
    const phone =
      /^(?!.*(\d)(-?\1){4})(?!0123456789|1234567890|2345678901|3456789012|4567890123|5678901234|6789012345|7890123456|8901234567|9012345678)\d{10}$/;
    if (values.firstName === "") {
      errors.firstName = "First name required!";
      setErrFN(true);
    }
    if (values.firstName !== "") {
      setErrFN(false);
    }
    // else {
    //   setErrFN(false);
    // }

    if (values.contactNo === "") {
      errors.contactNo = "Contact number required!";
      setErrCN(true);
    } else if (!phone.test(values.contactNo)) {
      errors.contactNo = "This is not a valid Contact Number";
      setErrCN(true);
    }
    if (values.contactNo !== "") {
      setErrCN(false);
    }

    if (values.city === "") {
      errors.city = "Select City required!";
      setErrCI(true);
    }
    if (values.city !== "") {
      setErrCI(false);
    }

    if (values.countryId == "") {
      errors.countryId = "Select country name!";
      setErrCID(true);
    }
    if (values.countryId !== "") {
      setErrCID(false);
    }

    if (values.stateId == "") {
      errors.stateId = "Select state name!";
      setErrSID(true);
    }
    if (values.stateId !== "") {
      setErrSID(false);
    }

    if (values.addressLine1 == "") {
      errors.addressLine1 = "Address is Required!";
      setErrAL1(true);
    }
    if (values.addressLine1 !== "") {
      setErrAL1(false);
    }

    if (values.zipCode == "") {
      errors.zipCode = "zipCode is Required!";
      setErrZP(true);
    }
    if (values.zipCode !== "") {
      setErrZP(false);
    }

    return errors;
  };

  const validClassCountryName =
    errCN && isDisplayError ? "form-control is-invalid" : "form-control";
  const validClassCityName =
    errCI && isDisplayError ? "form-control is-invalid" : "form-control";
  const validClassStateName =
    errSID && isDisplayError ? "form-control is-invalid" : "form-control";

  const validClassAdd =
    errAL1 && isDisplayError ? "form-control is-invalid" : "form-control";

  const validClassFN =
    errFN && isDisplayError ? "form-control is-invalid" : "form-control";

  const validClassContact =
    errCN && isDisplayError ? "form-control is-invalid" : "form-control";
  const validClassZip =
    errZP && isDisplayError ? "form-control is-invalid" : "form-control";
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const delay = 1000;
    setTimeout(() => {
      setIsLoading(false);
    }, delay);
  }, []);
  return (
    <React.Fragment>
      {isLoading ? (
        // Loader component while loading
        <div className="loader-container">
          <Puff
            color="#c7a17a"
            height={50}
            width={50}
            timeout={0} // 0 means no timeout, loader will be displayed until setIsLoading(false) is called
          />
        </div>
      ) : (
        <div style={{ fontFamily: "Arial, Helvetica, sans-serif" }}>
          <Cart />
          <div>
            <Hader Color="#000" />
            <MobileHeader />
          </div>
          <BannerTwo Title="My Addresses" subTitle="My Addresses" />
          <Container className="sec_ptb_120 ">
            <Row>
              <Col lg="3">
                <ProfileSideBar />
              </Col>
              <Col lg="9">
                {ShippingAddressData.length > 0 ? (
                  ShippingAddressData.map((address) => (
                    <Card className="myProfileCard">
                      <p className="addressTitle">
                        {address.firstName} {address.lastName}
                      </p>
                      <p style={{ whiteSpace: "wrap" }}>
                        {address.addressLine1} <br />
                        {address.addressLine2 && address.addressLine2}
                        <br />
                        {address.city}
                        <br />
                        {address.State}
                        <br />
                        {address.Country}
                      </p>

                      <p className="addressTitle">{address.contactNo}</p>
                      <Row className="editDiv">
                        <Col lg={2}>
                          <Link
                            className="editlink"
                            to="#"
                            onClick={() => handleTog_edit(address._id)}
                          >
                            Edit
                          </Link>
                        </Col>
                        <Col lg={2}>
                          {" "}
                          <Link
                            className="editlink"
                            to="#"
                            onClick={() => tog_delete(address._id)}
                          >
                            Remove
                          </Link>
                        </Col>
                        <Col className="defaultCol" lg={8}>
                          {/* <input type="checkBox" />{" "}
                    <Link className="editlink" to="#">
                      {" "}
                      Default Addresses
                    </Link> */}
                          <div className="form-check mb-2">
                            <input
                              key={address._id}
                              type="checkbox"
                              name="defaultAdd"
                              value={defaultAdd}
                              // onChange={handlecheck}
                              onChange={(e) => handlecheck(e, address._id)}
                              // checked={defaultAdd}
                              checked={checkedAddresses.includes(address._id)}
                            />
                            <label
                              // className="form-check-label"
                              htmlFor="activeCheckBox"
                              className="editlink"
                            >
                              Default Addresses
                            </label>
                          </div>
                        </Col>
                      </Row>
                      <hr />
                    </Card>
                  ))
                ) : (
                  <h5 style={{ border: "3px solid red", padding: "10px" }} className="text-center mt-3">
                    Please Add Your Address to Continue! <hr />
                  </h5>
                )}

                <Link
                  className="btn btn-lg  p-3 m-3 "
                  style={{ border: "1px solid #c7a17a", fontSize: "15px" }}
                  // to="/add-new-address"
                  onClick={() => {
                    tog_list();
                  }}
                >
                  {" "}
                  <FaAddressBook className="m-1" />
                  Add New Address
                </Link>
              </Col>
            </Row>
            <Modal
              isOpen={modal_list}
              toggle={() => {
                tog_list();
              }}
              className="modal-lg"
              centered
            >
              <ModalHeader
                className="bg-light p-3"
                toggle={() => {
                  setmodal_list(false);
                }}
              >
                {isEdit ? "Edit Address" : "Add Address"}
              </ModalHeader>
              <form>
                <ModalBody style={{ backgroundColor: "white !important" }}>
                  <Row>
                    <Col lg={6}>
                      <Label style={{ marginTop: "5px" }}>
                        First Name<span className="text-danger">*</span>{" "}
                      </Label>
                      <div className="mb-3">
                        <Input
                          style={{
                            borderRadius: "0px",
                            padding: "10px",
                          }}
                          type="text"
                          className={validClassFN}
                          placeholder="Enter First Name "
                          required
                          name="firstName"
                          value={firstName}
                          onChange={handleChangeOption}
                        />

                        {isDisplayError && (
                          <p className="text-danger">{formErrors.firstName}</p>
                        )}
                      </div>
                    </Col>

                    <Col lg={6}>
                      <div className="mb-3">
                        <Label style={{ marginTop: "5px" }}>Last Name</Label>

                        <Input
                          style={{
                            borderRadius: "0px",
                            padding: "10px",
                          }}
                          type="text"
                          className="form-control"
                          placeholder="Enter last Name"
                          name="lastName"
                          value={lastName}
                          onChange={handleChangeOption}
                        />
                      </div>
                    </Col>
                  </Row>

                  <Row>

                    <Col lg={6}>
                      <div className=" mb-3">
                        <Label style={{ marginTop: "5px" }}>Company Name</Label>

                        <Input
                          style={{
                            borderRadius: "0px",
                            padding: "10px",
                          }}
                          type="text"
                          className="form-control"
                          placeholder="Enter company name"
                          name="companyName"
                          value={companyName}
                          onChange={handleChangeOption}
                        />
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={12}>
                      <div className=" mb-3">
                        <Label style={{ marginTop: "5px" }}>
                          address Line 1<span className="text-danger">*</span>{" "}
                        </Label>

                        <Input
                          style={{
                            borderRadius: "0px",
                            padding: "10px",
                            height: "50px",
                          }}
                          type="textarea"
                          // style={{ height: "50px" }}
                          className={validClassAdd}
                          placeholder="Enter address"
                          name="addressLine1"
                          value={addressLine1}
                          onChange={handleChangeOption}
                        />
                        {isDisplayError && (
                          <p className="text-danger">
                            {formErrors.addressLine1}
                          </p>
                        )}
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div className=" mb-3">
                        <Label style={{ marginTop: "5px" }}>
                          address Line 2
                        </Label>

                        <Input
                          style={{
                            height: "50px",
                            borderRadius: "0px",
                            padding: "10px",
                          }}
                          type="textarea"
                          className="form-control"
                          placeholder="Enter address"
                          name="addressLine2"
                          value={addressLine2}
                          onChange={handleChangeOption}
                        />
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col hidden lg={6}>
                      <div className="  mb-3">
                        <Label style={{ marginTop: "5px" }}>
                          Country <span className="text-danger">*</span>
                        </Label>
                        <select
                          style={{
                            borderRadius: "0px",
                            padding: "10px",
                          }}
                          name="countryId"
                          className={validClassCountryName}
                          onChange={handleChangeOption}
                          value={countryId}
                          data-choices
                          data-choices-sorting="true"
                        >
                          <option>Select Country</option>
                          {countries.map((c) => {
                            return (
                              <React.Fragment key={c._id}>
                                {c.isActive && (
                                  <option value={c._id}>{c.CountryName}</option>
                                )}
                              </React.Fragment>
                            );
                          })}
                        </select>

                        {isDisplayError && (
                          <p className="text-danger">{formErrors.countryId}</p>
                        )}
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className=" mb-3">
                        <Label style={{ marginTop: "5px" }}>
                          City<span className="text-danger">*</span>{" "}
                        </Label>

                        <Input
                          style={{
                            borderRadius: "0px",
                            padding: "10px",
                          }}
                          type="text"
                          className={validClassCityName}
                          placeholder="Enter city"
                          name="city"
                          value={city}
                          onChange={handleChangeOption}
                        />
                        {isDisplayError && (
                          <p className="text-danger">{formErrors.city}</p>
                        )}
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="  mb-3">
                        <Label style={{ marginTop: "5px" }}>
                          State <span className="text-danger">*</span>
                        </Label>
                        <select
                          style={{
                            borderRadius: "0px",
                            padding: "10px",
                          }}
                          name="stateId"
                          className={validClassStateName}
                          onChange={handleChangeOption}
                          value={stateId}
                          data-choices
                          data-choices-sorting="true"
                        >
                          <option>Select State </option>
                          {states.map((s) => {
                            return (
                              <React.Fragment key={s._id}>
                                {s.isActive && countryId === s.CountryID && (
                                  <option value={s._id}>{s.StateName}</option>
                                )}
                              </React.Fragment>
                            );
                          })}
                        </select>

                        {isDisplayError && (
                          <p className="text-danger">{formErrors.stateId}</p>
                        )}
                      </div>
                    </Col>
                  </Row>

                  <Row>


                    <Col lg={6}>
                      <div className=" mb-3">
                        <Label style={{ marginTop: "5px" }}>
                        Zipcode* Only Digits (Example: 12345)<span className="text-danger">*</span>{" "}
                        </Label>

                        <Input
                          style={{
                            borderRadius: "0px",
                            padding: "10px",
                          }}
                          type="text"
                          className={validClassZip}
                          placeholder="Enter zip code"
                          name="zipCode"
                          value={zipCode}
                          onChange={handleChangeOption}
                        />
                        {isDisplayError && (
                          <p className="text-danger">{formErrors.zipCode}</p>
                        )}
                      </div>
                    </Col>

                    <Col lg={6}>
                      <div className=" mb-3">
                        <Label style={{ marginTop: "5px" }}>
                          Contact No (10 Digits - Ex - 1112223333) <span className="text-danger">*</span>{" "}
                        </Label>

                        <Input
                          style={{
                            borderRadius: "0px",
                            padding: "10px",
                          }}
                          type="tel"
                          className={validClassContact}
                          placeholder="Contact no"
                          name="contactNo"
                          maxLength="10"
                          value={contactNo}
                          onChange={handleChangeOption}
                        />
                        {isDisplayError && (
                          <p className="text-danger">{formErrors.contactNo}</p>
                        )}
                      </div>
                    </Col>
                  </Row>

                </ModalBody>
                <ModalFooter style={{ backgroundColor: "white !important" }}>
                  <div className="hstack gap-2 justify-content-end">
                    <button
                      type="submit"
                      className="btn btn-dark"
                      id="add-btn"
                      onClick={isEdit ? handleUpdate : handleSubmit}
                    // onClick={handleAddOptions}
                    >
                      {isEdit ? "Update" : "Add"}
                    </button>
                    <button
                      type="button"
                      className="btn btn-outline-dark"
                      onClick={() => {
                        setmodal_list(false);
                        setValues(initialState);
                        setIsDIsplayError(false);
                        setIsEdit(false);
                        setIsSubmit(false);
                        // setIsSubmit(false);
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </ModalFooter>
              </form>
            </Modal>

            <Modal
              isOpen={modal_delete}
              toggle={() => {
                tog_delete();
              }}
              centered
            >
              <ModalHeader
                className="bg-light p-3"
                toggle={() => {
                  setmodal_delete(false);
                }}
              >
                Remove Shipping Address
              </ModalHeader>
              <form>
                <ModalBody>
                  <div className="mt-2 text-center">
                    <lord-icon
                      src="https://cdn.lordicon.com/gsqxdxog.json"
                      trigger="loop"
                      colors="primary:#f7b84b,secondary:#f06548"
                      style={{ width: "100px", height: "100px" }}
                    ></lord-icon>
                    <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                      <h4>Are you sure ?</h4>
                      <p className="text-muted mx-4 mb-0">
                        You want to Remove this Shipping Address
                      </p>
                    </div>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <div className="hstack gap-2 justify-content-end">
                    <button
                      type="submit"
                      style={{ backgroundColor: "#f06548" }}
                      className="btn btn-danger"
                      id="add-btn"
                      onClick={handleDelete}
                    >
                      Remove
                    </button>

                    <button
                      type="button"
                      className="btn btn-outline-danger"
                      onClick={() => setmodal_delete(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </ModalFooter>
              </form>
            </Modal>
          </Container>

          <Footer />
        </div>
      )}
    </React.Fragment>
  );
};

export default MyAddress;
