import React, { useEffect, useState } from "react";
import Hader from "../Component/Hader";
import Footer from "../Component/Footer";
import BradeCrum from "../Component/BradeCrum";
import Form from "react-bootstrap/Form";
import { Col, Container, Row, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import FindStoreCaresol from "../Component/FindStoreCaresol";
import axios from "axios";
import { listCity, listState } from "../functions/Location/Location";
import Carousel from "react-multi-carousel";
import MyMap from "./VectorMap";
import { ToastContainer, toast } from "react-toastify";
import { findLocation } from "../functions/Location/companyLocation";
import { Add } from "@mui/icons-material";
import { Puff } from "react-loader-spinner";
import { FaLocationDot } from "react-icons/fa6";
import MobileHeader from "../Component/MobileHeader";

const FindStore = () => {
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);

  const [StateID, setStateID] = useState();
  const [CityID, setCity] = useState();

  const [companyLocation, setCompanyLocation] = useState([]);

  useEffect(() => {
    loadStates();
    // loadStates();
    loadCities();
  }, []);

  const loadStates = () => {
    listState().then((res) => {
      console.log("country", res.data);
      setStateList(res.data);
    });
  };

  const loadCities = () => {
    listCity().then((res) => {
      setCityList(res.data);
      console.log("city", res.data);
    });
  };
  const handleLocate = () => {
    window.scrollTo({
      top: 300, // Set the vertical scroll position here
      behavior: "smooth",
    });

    findLocation(StateID, CityID).then((res) => {
      const d = res.data.filter((c) => c.IsActive);
      setCompanyLocation(d);
      if (d.length === 0) {
        toast.error("Store Not Found");
      }
      console.log("find locations", d);
    });
  };

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const delay = 1000;
    setTimeout(() => {
      setIsLoading(false);
    }, delay);
  }, []);

  return (
    <React.Fragment>
      {isLoading ? (
        // Loader component while loading
        <div className="loader-container">
          <Puff
            color="#c7a17a"
            height={50}
            width={50}
            timeout={0} // 0 means no timeout, loader will be displayed until setIsLoading(false) is called
          />
        </div>
      ) : (
        <>
          <ToastContainer />

          <Hader Color="#000" />
          <MobileHeader />
          <BradeCrum Title="Find Store" />
          <div className="sec_ptb_120">
            <Container>
              <Row>
                <div>
                  {/* <h2 className="findTitle">FIND A NEARBY STORE</h2> */}
                  <Container>
                    {/* <Carousel responsive={responsive}>
                  {companyLocation?.map((addressData) => (
                    <div className="testimonials-item">
                      {addressData.IsActive ? (
                        <Card className="addreshCard">
                          <div className="iconDiv">
                            <img
                              className="iconAdress"
                              src={`${process.env.REACT_APP_API_URL_COFFEE}/${addressData.StoreLogo}`}
                              alt="icon"
                            />
                          </div>

                          <div>
                            <h4 className="addressTitle mt-2">
                              {addressData.Location}
                            </h4>
                            <p>{addressData.Address}</p>
                          </div>
                        </Card>
                      ) : null}
                    </div>
                  ))}
                </Carousel> */}
                  </Container>
                  <section className="row section section-padding background-img bg-img-7 p-t-70 p-b-70 m-b-0 mt-4">
                    <div className="col-lg-6 col-12">
                      <MyMap locations={companyLocation} />
                    </div>
                    <div className="col-lg-6 col-12">
                      <div className="section-container small">
                        {/* Block Contact Form */}
                        <div className="block block-contact-form">
                          <div className="block-widget-wrap">
                            <div className="block-title">
                              <h2>FIND A NEARBY STORE</h2>
                            </div>
                            <div className="block-content">
                              <form
                                action=""
                                method="post"
                                className="contact-form"
                                noValidate="novalidate"
                              >
                                <div className="contact-us-form">
                                  <div className="row">
                                    <div className="col-sm-12 col-md-12">
                                      <label className="required mb-3">
                                        State
                                      </label>
                                      <br />

                                      <Form.Select
                                        // className="form-control-wrap"
                                        className="store-loacte-select"
                                        aria-label="Default select example"
                                        value={StateID}
                                        onChange={(e) => {
                                          setStateID(e.target.value);
                                          console.log(e.target.value);
                                        }}
                                      >
                                        <option>Select State</option>
                                
                                        {stateList.filter((c) => cityList.some(ld => ld.StateID === c._id)).map((c) => {
                                          return (
                                            c.isActive && (
                                              <option value={c._id}>
                                                {" "}
                                                {c.StateName}{" "}
                                              </option>
                                            )
                                          );
                                        })}
                                      </Form.Select>
                                    </div>
                                    <div className="col-sm-12 col-md-12 mt-4">
                                      <label className="required mb-3">
                                        City
                                      </label>
                                      <br />

                                      <Form.Select
                                        // className="form-control-wrap"
                                        className="store-loacte-select"
                                        aria-label="Default select example"
                                        name="CityID"
                                        value={CityID}
                                        onChange={(e) => {
                                          setCity(e.target.value);
                                          console.log(e.target.value);
                                        }}
                                      >
                                        <option>Select City</option>
                                        {cityList.filter((C) => C._id !== "668f135fcc360d3074831298").map((C) => {
                                          return (
                                            C.isActive &&
                                            StateID == C.StateID && (
                                              <option value={C._id}>
                                                {" "}
                                                {C.CityName}{" "}
                                              </option>
                                            )
                                          );
                                        })}
                                      </Form.Select>
                                    </div>
                                  </div>
                                  <div
                                    className="form-button"
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <input
                                      type="button"
                                      defaultValue="Locate"
                                      // className="button mt-4"
                                      className="custom-button btn-lg mt-4"
                                      onClick={handleLocate}
                                    />
                                  </div>

                                  {/* {companyLocation.length === 0 ? (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <h3 style={{ marginTop: "50px" }}>
                                    <i>Store Not Found</i>
                                  </h3>
                                </div>
                              ) : null} */}
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
                {/* <Col lg="6" md="6">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6231.124989407801!2d-121.00138799999999!3d38.658939!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x809af008877e65d1%3A0x1415120758c05ced!2sRC%20Henning%20Coffee%20Company!5e0!3m2!1sen!2sin!4v1711617418695!5m2!1sen!2sin"
                width="100%"
                height="450"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </Col>
            <Col lg="6" md="6">
              <p>State</p>
              <Form.Select
                className="findSelector"
                aria-label="Default select example"
                name="stateId"
                onChange={(e) => handleSetIds(e)}
              >
                <option>Select State</option>
                {stateList.length > 0 &&
                  stateList.map((it) => (
                    <option value={it._id}>{it.StateName}</option>
                  ))}
              </Form.Select>
              <p>City</p>
              <Form.Select
                className="findSelector"
                aria-label="Default select example"
                name="cityId"
                onChange={(e) => handleSetIds(e)}
              >
                <option>Select City</option>
                {cityList.length > 0 &&
                  cityList.map((it) => (
                    <option value={it._id}>{it.CityName}</option>
                  ))}
              </Form.Select>
              <div
                className="abtn_wrap text-lg-end text-md-end wow fadeInUp overBlogSection"
                data-wow-delay=".3s"
              >
                <Link
                  className="btn btn_border border_black"
                  onClick={handleGetLoation}
                >
                  LOCATE
                </Link>
              </div>
            </Col> */}
              </Row>
              <FindStoreCaresol companyLocation={companyLocation} />
              <Link to="/location">
                <div className="locationDiv">
                  <FaLocationDot /> VIEW ALL LOCATIONS
                </div>
              </Link>
            </Container>
          </div>
          <Footer />
        </>
      )}
    </React.Fragment>
  );
};

export default FindStore;
