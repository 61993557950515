import React, { useState, useContext, useEffect } from "react";
import Hader from "../Component/Hader";
// import MobileHader from "../Component/MobileHader";
import { MyContext } from "../MyContext";

import Footer from "../Component/Footer";
// import BannerTwo from "../Component/BannerTwo";
// import ProductList from "./ProductList";
import { Button, Card, Col, Container, Row, Table } from "react-bootstrap";
import { RxCross2 } from "react-icons/rx";
import { Link, useNavigate } from "react-router-dom";
import { FaGoogle, FaTrashAlt } from "react-icons/fa";
import { CiShoppingTag } from "react-icons/ci";
import { CiGift } from "react-icons/ci";
import {
  MdOutlineNoteAlt,
  MdOutlineShoppingCartCheckout,
} from "react-icons/md";
import "../assets/css/shoppingCart.css";
import { SlPaypal } from "react-icons/sl";
import { FaGooglePay } from "react-icons/fa";
import { LiaShippingFastSolid } from "react-icons/lia";
import { IoLockClosedSharp } from "react-icons/io5";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Cart from "../Component/Cart";
import {
  decreaseQuantityGuestCartData,
  getUserCartByUserId,
  increaseQuantityGuestCartData,
  RemoveFromGuestCart,
  updateQuantity,
} from "../functions/Auth/UserCart";
import { getProductVariantsInfoInCart } from "../functions/ProductDetails/ProductDetails";
// import Counter from "../Component/Quantity";
import {
  increaseQuantityOne,
  RemoveFromCart,
  decreaseQuantityOne,
} from "../functions/Auth/UserCart";
import Counter from "../Component/Counter";
import BradeCrum from "../Component/BradeCrum";

import { Puff } from "react-loader-spinner";
import MobileHeader from "../Component/MobileHeader";
import { toast, ToastContainer } from "react-toastify";
const ShoppingCart = () => {
  const {
    setcartDetails,
    setSubTotal,
    setCartCount,
    cartCount,
    count,
    setCount,
    prodVariantsData,
    setProdVariantsData,
    cartDetails,
    subTotal,
  } = useContext(MyContext);
  useEffect(() => {
    loadUserCart();
  }, []);

  const userId = localStorage.getItem("NicksCoUser");
  const navigate = useNavigate();

  const handleCheckoutClick = () => {
    if (userId) {
      navigate("/checkout");
    } else {
      console.log("not found user")
      navigate("/register");
    }
  };

  // const [prodVariantsData, setProdVariantsData] = useState([]);

  const handleRemoveItem = (id, productVariant, subsId) => {
    if (userId) {
      RemoveFromCart(userId, id, productVariant, subsId).then((res) => {
        console.log("remove item", res.data);
        // setcartDetails(res.data);
        getUserCartByUserId(userId).then((res1) => {
          console.log("cart data", res1.data);
          const resp = res1.data.data;
          setcartDetails(resp);
          setSubTotal(res1.data.subTotal);
          setCartCount(resp.length);
        });
      });
    } else {
      RemoveFromGuestCart(
        id,
        productVariant,
        subsId,
        localStorage.getItem("guestCartData")
          ? JSON.parse(localStorage.getItem("guestCartData"))
          : []
      ).then((res) => {
        console.log("remove item", res.data);
        if (res.data.isOk) {
          const dataString = JSON.stringify(res.data.guestCartData);
          const guestCartData = localStorage.setItem(
            "guestCartData",
            dataString
          );
          const cartArray = res.data.guestCartData;
          setCartCount(cartArray.length);

          const invertedData = cartArray.reverse(); // Reverse the order of the array
          setcartDetails(invertedData);

          let subAmt = 0;

          const mappedCart = cartArray.map((item) => {
            const totalAmount = item.amount * item.quantity;
            subAmt = subAmt + totalAmount;
          });
          setSubTotal(subAmt);
        }
      });
    }
  };
  // Function to handle decrementing the counter

  const loadUserCart = async () => {
    try {
      if (userId) {
        const res = await getUserCartByUserId(userId);
        console.log("cart data", res.data);
        const resp = res.data.data;
        setcartDetails(resp);
        setCartCount(resp.length);
        setSubTotal(res.data.subTotal);

        // Create an array to store promises
        const promises = resp.map((item) => {
          console.log("variantid", item.productVariantsId);
          if (item.productVariantsId !== null)
            return getProductVariantsInfoInCart(item.productVariantsId).then(
              (res1) => {
                console.log("product variants info", res1.data[0]);
                return res1.data[0]; // Return the object directly
              }
            );
        });

        const variantDataArray = await Promise.all(promises.filter(Boolean));

        setProdVariantsData(variantDataArray);
      } else {
        const cartArray = JSON.parse(localStorage.getItem("guestCartData"));
        setCartCount(cartArray.length);
        console.log("Cart count:", cartArray);
        const invertedData = cartArray.reverse(); // Reverse the order of the array
        setcartDetails(invertedData);

        let subAmt = 0;

        const mappedCart = cartArray.map((item) => {
          const totalAmount = item.amount * item.quantity;
          subAmt = subAmt + totalAmount;
        });
        setSubTotal(subAmt);

        const promises = cartArray.map((item) => {
          console.log("variantid", item.productVariantsId);
          if (item.productVariantsId !== null)
            return getProductVariantsInfoInCart(item.productVariantsId).then(
              (res1) => {
                console.log("product variants info", res1.data[0]);
                return res1.data[0]; // Return the object directly
              }
            );
        });

        const variantDataArray = await Promise.all(promises.filter(Boolean));

        setProdVariantsData(variantDataArray);
      }
    } catch (error) {
      console.error("Error loading user cart:", error);
    }
  };

  const handleAddItem = (productid, productvariantid, subsId) => {
    console.log("add item");

    if (userId) {
      increaseQuantityOne(userId, productid, productvariantid, subsId).then(
        (res) => {
          console.log("updated cart", res.data);
          loadUserCart();
        }
      );
    } else {
      console.log("outs");
      increaseQuantityGuestCartData(
        productid,
        productvariantid,
        subsId,
        localStorage.getItem("guestCartData")
          ? JSON.parse(localStorage.getItem("guestCartData"))
          : []
      ).then((res) => {
        console.log("updated cart", res.data);
        if (res.data.isOk) {
          const dataString = JSON.stringify(res.data.guestCartData);
          const guestCartData = localStorage.setItem(
            "guestCartData",
            dataString
          );
          const cartArray = res.data.guestCartData;
          setCartCount(cartArray.length);

          const invertedData = cartArray.reverse(); // Reverse the order of the array
          setcartDetails(invertedData);

          let subAmt = 0;

          const mappedCart = cartArray.map((item) => {
            const totalAmount = item.amount * item.quantity;
            subAmt = subAmt + totalAmount;
          });
          setSubTotal(subAmt);
        }
      });
    }
  };
  const handleSubtractItem = (productid, productvariantid, subsId) => {
    console.log("subtract item");

    if (userId) {
      decreaseQuantityOne(userId, productid, productvariantid, subsId).then(
        (res) => {
          console.log("rr", res);
          if (res.data.isOk) {
            console.log("updated cart", res.data.data);
            loadUserCart();
          } else {
            toast.error("Minimum quantity reached");
          }
        }
      );
    } else {
      console.log("outs dec");
      decreaseQuantityGuestCartData(
        productid,
        productvariantid,
        subsId,
        localStorage.getItem("guestCartData")
          ? JSON.parse(localStorage.getItem("guestCartData"))
          : []
      ).then((res) => {
        console.log("updated cart", res.data);
        if (res.data.isOk) {
          const dataString = JSON.stringify(res.data.guestCartData);
          const guestCartData = localStorage.setItem(
            "guestCartData",
            dataString
          );
          const cartArray = res.data.guestCartData;
          setCartCount(cartArray.length);

          const invertedData = cartArray.reverse(); // Reverse the order of the array
          setcartDetails(invertedData);

          let subAmt = 0;

          const mappedCart = cartArray.map((item) => {
            const totalAmount = item.amount * item.quantity;
            subAmt = subAmt + totalAmount;
          });
          setSubTotal(subAmt);
        }
      });
    }
  };

  const [togglePromoBtn, setTogglePromoBtn] = useState({
    promo: false,
    note: false,
  });

  const handlePromoBtn = () => {
    if (togglePromoBtn.promo == true) {
      setTogglePromoBtn({
        promo: false,
        note: false,
      });
    } else {
      setTogglePromoBtn({
        promo: true,
        note: false,
      });
    }
  };

  const handleNoteBtn = () => {
    if (togglePromoBtn.note == true) {
      setTogglePromoBtn({
        promo: false,
        note: false,
      });
    } else {
      setTogglePromoBtn({
        promo: false,
        note: true,
      });
    }
  };

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }));
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const delay = 1000;
    setTimeout(() => {
      setIsLoading(false);
    }, delay);
  }, []);

  return (
    <React.Fragment>
      {isLoading ? (
        // Loader component while loading
        <div className="loader-container">
          <Puff
            color="#c7a17a"
            height={50}
            width={50}
            timeout={0} // 0 means no timeout, loader will be displayed until setIsLoading(false) is called
          />
        </div>
      ) : (
        <div>
          <Cart />
          <Hader Color="#000" />
          <ToastContainer />
          <MobileHeader />
          <BradeCrum />
          {/* <MobileHader /> */}
          {/* <BannerTwo Title="Cart" subTitle="Cart" /> */}

          <div class="container">
            <Row className="m-5 d-flex justify-content-center">
              <Col lg={8}>
                <div className="order-summery-card">
                  <div className="order-card-header">
                    <h5>My Cart</h5>
                    <Link to="/" className="btn_text m-1">
                      <span style={{ fontSize: "18px", fontWeight: "bold" }}>Continue Browsing</span>
                    </Link>
                  </div>
                  <hr></hr>

                  {/* <table>
                    <tbody>
                      {cartDetails.map((item) => (
                        <tr key={item.id}>
                          <td style={{ width: "40%" }}>
                            <img
                              className="order-product-image"
                              src={`${process.env.REACT_APP_API_URL_COFFEE}/${item.productImage}`}
                              alt={item.productName}
                            />{" "}
                          </td>
                          <td
                            className="order-summery-name-price"
                            style={{ width: "40%" }}
                          >
                            <h6>{item.productName} </h6>{" "}
                            <p>Price : ${item.totalAmount.toFixed(2)} </p>
                            {item.subsId && (
                              <p>
                                <b>
                                  <i>Subscribed:</i>
                                </b>{" "}
                                <i> {item.subsName} </i>
                              </p>
                            )}
                            {prodVariantsData.find(
                              (variant) =>
                                variant._id === item.productVariantsId
                            ) && (
                              <p>
                                {prodVariantsData
                                  .find(
                                    (variant) =>
                                      variant._id === item.productVariantsId
                                  )
                                  .parameters.map((param, index) => (
                                    <React.Fragment key={index}>
                                      <b>{param.parameterName}</b>:{" "}
                                      {param.parameterValue}
                                      <br />
                                    </React.Fragment>
                                  ))}
                              </p>
                            )}
                          </td>
                          <td style={{ width: "10%" }}>
                           
                            <div className="quantity-container">
                              <button
                                className="quantity-button"
                                // onClick={decrement}
                                onClick={() => {
                                  handleSubtractItem(
                                    item.productId,
                                    item.productVariantsId,
                                    item.subsId
                                  );
                                }}
                              >
                                –
                              </button>
                              <div> {item.quantity}</div>
                              <button
                                className="quantity-button"
                                onClick={() => {
                                  handleAddItem(
                                    item.productId,
                                    item.productVariantsId,
                                    item.subsId
                                  );
                                }}
                              >
                                +
                              </button>
                            </div>
                          </td>
                          <td style={{ width: "10%" }}>
                            <a
                              onClick={() => {
                                handleRemoveItem(
                                  item.productId,
                                  item.productVariantsId,
                                  item.subsId
                                );
                              }}
                              className="delete-link"
                            >
                              <FaTrashAlt />
                            </a>
                          </td>
                          <hr style={{ width: "500%" }}></hr>
                        </tr>
                      ))}
                    </tbody>
                  </table> */}

                  <Container>
                    {cartDetails.map((item) => (
                      <Row key={item.id}>
                        <Col lg={3} md={6} sm={12}>
                          <img
                            className="order-product-image"
                            // src={item.productImage}
                            src={`${process.env.REACT_APP_API_URL_COFFEE}/${item.productImage}`}
                            alt={item.productName}
                          />{" "}
                        </Col>
                        <Col lg={3} md={6} sm={12}>
                          <h6>{item.productName} </h6>{" "}
                          {/* <p>Price :${item.amount * item.quantity} </p> */}
                          <p>Price : ${item.totalAmount.toFixed(2)} </p>
                          {item.subsId && (
                            <p>
                              <b>
                                <i>Subscribed:</i>
                              </b>{" "}
                              <i> {item.subsName} </i>
                            </p>
                          )}
                          {prodVariantsData.find(
                            (variant) => variant._id === item.productVariantsId
                          ) && (
                              <p>
                                {prodVariantsData
                                  .find(
                                    (variant) =>
                                      variant._id === item.productVariantsId
                                  )
                                  .parameters.map((param, index) => (
                                    <React.Fragment key={index}>
                                      <b>{param.parameterName}</b>:{" "}
                                      {param.parameterValue}
                                      <br />
                                    </React.Fragment>
                                  ))}
                              </p>
                            )}
                        </Col>
                        <Col lg={3} md={3} className="col">
                          <div className="quantity-container">
                            <button
                              className="quantity-button"
                              // onClick={decrement}
                              onClick={() => {
                                handleSubtractItem(
                                  item.productId,
                                  item.productVariantsId,
                                  item.subsId
                                );
                              }}
                            >
                              –
                            </button>
                            <div> {item.quantity}</div>
                            <button
                              className="quantity-button"
                              onClick={() => {
                                handleAddItem(
                                  item.productId,
                                  item.productVariantsId,
                                  item.subsId
                                );
                              }}
                            >
                              +
                            </button>
                          </div>
                        </Col>
                        <Col lg={3} md={6} className="col">
                          <a
                            onClick={() => {
                              handleRemoveItem(
                                item.productId,
                                item.productVariantsId,
                                item.subsId
                              );
                            }}
                            className="delete-link"
                          >
                            <FaTrashAlt />
                          </a>
                        </Col>
                      </Row>
                    ))}
                  </Container>

                  <hr />
                  <div className="d-flex flex-column">
                    <Link className="btn_text m-1">
                      {/* <span onClick={handlePromoBtn}> */}
                      <CiShoppingTag /> Happy Shopping!
                      {/* </span> */}
                    </Link>
                    <div
                      style={{
                        display: `${togglePromoBtn.promo ? "flex" : "none"}`,
                        justifyContent: "space-between",
                      }}
                    >
                      <input
                        type="text"
                        placeholder="Promo code"
                        className="m-2"
                        style={{
                          border: "none",
                          borderBottom: "1px solid black",
                        }}
                      />
                      <Button
                        style={{ backgroundColor: "#c7a17a", border: "none" }}
                      >
                        Apply
                      </Button>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={4}>
                <div className="order-summery-card">
                  <div className="order-card-header">
                    <h5>Order Summary</h5>
                  </div>
                  <hr></hr>

                  <div className="d-flex justify-content-between">
                    <h6>Subtotal</h6>
                    {/* <p>$1100</p> */}
                    <p>${subTotal.toFixed(2)}</p>
                  </div>

                  {
                  //  <Link
                  //    to="#"
                  //    className="btn_text m-1"
                  //    style={{ textDecoration: "none" }}
                  //  >
                  //    <span style={{ cursor: "default" }}>
                  //      <LiaShippingFastSolid /> Estimated Delivery
                  //    </span>
                  //  </Link>
                  }

                  <hr />
                  <div className="d-flex justify-content-between">
                    <h6>Total</h6> <p>${subTotal.toFixed(2)}</p>
                    {/* <p>$1100</p> */}
                  </div>

                  <div className="shopping-cart-btn-div">
                    <Link
                      className="btn  btn-warning"
                      // onClick={handleCheckoutClick}
                      // to={ userId ? "/checkout" : "/login" }
                      to="/checkout"
                    >
                      <MdOutlineShoppingCartCheckout /> Checkout
                    </Link>
                    {/* <Button className="btn btn-light">
                      <SlPaypal /> paypal
                    </Button>
                    <Button className="btn btn-dark">
                      <FaGoogle /> pay
                    </Button> */}
                    <p className="secure-checkout-p">
                      <IoLockClosedSharp /> Secure Checkout
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </div>

          <Footer />
        </div>
      )}
    </React.Fragment>
  );
};

export default ShoppingCart;
