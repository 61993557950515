import React, { useState, useEffect } from "react";
import { Card, Label, Input, Row, Container } from "reactstrap";
import "./../assets/css/login.css";
import * as Yup from "yup";
import Hader from "../Component/Hader";
import { ForgotPassword } from "../functions/Auth/Users";
import axios from "axios";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cart from "../Component/Cart";
import MobileHeader from "../Component/MobileHeader";

const initialState = {
  email: "",
};

const ForgotPasswordPage = () => {
  const formData = new FormData();
  const [values, setValues] = useState(initialState);
  const { email } = values;

  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleMouseOver = (event) => {
    event.target.style.color = '#fff';
  };

  const handleMouseOut = (event) => {
    event.target.style.color = '#000';
  };

  const handleTouchStart = (event) => {
    event.target.style.color = '#fff';
  };

  const handleTouchEnd = (event) => {
    event.target.style.color = '#000';
  };

  const [passwordShown, setPasswordShown] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordShown(!passwordShown);
  };

  const [errEmail, seterrEmail] = useState(false);
  const [errFN, setErrFN] = useState(false);
  const [errLN, setErrLN] = useState(false);

  const [errPassword, setErrPassword] = useState(false);
  // const Completionist = () => <span>OTP is not valid now!</span>;

  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (values.email === "") {
      errors.email = "Email is required!";
      seterrEmail(true);
    } else if (!regex.test(values.email)) {
      errors.email = "Invalid Email address!";
      seterrEmail(true);
    } else {
      seterrEmail(false);
    }

    return errors;
  };

  const validClassEmail =
    errEmail && isSubmit ? "form-control is-invalid" : "form-control";
  const validClassPassword =
    errPassword && isSubmit ? "form-control is-invalid" : "form-control pe-5";

  const validClassFN =
    errFN && isSubmit ? "form-control is-invalid" : "form-control";

  const validClassLN =
    errLN && isSubmit ? "form-control is-invalid" : "form-control pe-5";

  const handleClick = (e) => {
    e.preventDefault();
    setFormErrors({});
    let erros = validate(values);
    setFormErrors(erros);
    setIsSubmit(true);
    console.log("email id isss", email);

    formData.append("email", email);

    // Add any additional fields, for example, OTP
    if (Object.keys(erros).length === 0) {
      axios
        .post(
          `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/forgot/password/users`,
          values
        )
        .then((res) => {
          console.log("res", res.data);
          if (res.data.isOk) {
            toast.success("check your email!");
            setValues(initialState);
            setIsSubmit(false);
            setFormErrors({});
          } else {
            setFormErrors({ email: "email not found!" });
          }
          // if (res.data.msg === "User not found!") {
          //   setFormErrors({ email: "email not found!" });
          // }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <React.Fragment>
      <div>
    <Hader Color="#000" />
        <MobileHeader />
      </div>
      <ToastContainer />
      <Cart />
      <Container>
        <div
          className="loginDiv"
          style={{ fontFamily: "Arial, Helvetica, sans-serif" }}
        >
          <div className="form">
            {/* Passing handleSubmit parameter tohtml form onSubmit property */}
            <Card className="loginCard">
              <h3 className="text-center mb-3">Forgot Password?</h3>
              <form>
                <div className="mb-2 mt-3">
                  <input
                    id="email"
                    type="email"
                    className={validClassFN}
                    placeholder="Enter your Email"
                    required
                    name="email"
                    value={email}
                    style={{ marginBottom: 0 }}
                    onChange={handleChange}
                  />
                  {isSubmit && (
                    <p className="text-danger" style={{ textAlign: "left" }}>
                      {formErrors.email}
                    </p>
                  )}
                </div>

                {/* Click on submit button to submit the form */}
                <button 
                style={{ color: "#000" }}
                onMouseOver={handleMouseOver}
                onMouseOut={handleMouseOut}
                onTouchStart={handleTouchStart}
                onTouchEnd={handleTouchEnd}
                type="submit" onClick={handleClick}>
                  Submit
                </button>
              </form>
            </Card>
          </div>
        </div>
      </Container>
    </React.Fragment>
  );
};

export default ForgotPasswordPage;
