import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { BarLoader } from "react-spinners"; // Import BarLoader
import { Card } from "reactstrap";
import "./../assets/css/login.css";
import Hader from "../Component/Hader";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cart from "../Component/Cart";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

import { Container } from "react-bootstrap";
import MobileHeader from "../Component/MobileHeader";

const initialState = {
  newpassword: "",
  confirmpassword: "",
  timestamp: 0,
};

const ChangeNewPasswordPage = () => {
  const formdata = new FormData();
  const [values, setValues] = useState(initialState);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const handleMouseOver = (event) => {
    event.target.style.color = '#fff';
  };

  const handleMouseOut = (event) => {
    event.target.style.color = '#000';
  };

  const handleTouchStart = (event) => {
    event.target.style.color = '#fff';
  };

  const handleTouchEnd = (event) => {
    event.target.style.color = '#000';
  };

  const navigate = useNavigate();
  const { newpassword, confirmpassword } = values;
  const queryParams = new URLSearchParams(window.location.search);

  // Accessing specific query parameters
  const param1 = queryParams.get("timestamp");
  values.timestamp = param1;

  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const [errEmail, seterrEmail] = useState(false);
  const [errFN, setErrFN] = useState(false);
  const [errLN, setErrLN] = useState(false);

  const [errPassword, setErrPassword] = useState(false);

  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (values.email === "") {
      errors.email = "Email is required!";
      seterrEmail(true);
    } else if (!regex.test(values.email)) {
      errors.email = "Invalid Email address!";
      seterrEmail(true);
    } else {
      seterrEmail(false);
    }
    if (values.password === "") {
      errors.password = "Password is required!";
      setErrPassword(true);
    }
    if (values.password !== "") {
      setErrPassword(false);
    }

    if (values.firstName === "") {
      errors.firstName = "First Name is required!";
      setErrFN(true);
    }
    if (values.firstName !== "") {
      setErrFN(false);
    }

    if (values.lastName === "") {
      errors.lastName = "Last Name is required!";
      setErrLN(true);
    }
    if (values.lastName !== "") {
      setErrLN(false);
    }
    return errors;
  };
  const validClassEmail =
    errEmail && isSubmit ? "form-control is-invalid" : "form-control";
  const validClassPassword =
    errPassword && isSubmit ? "form-control is-invalid" : "form-control pe-5";

  const validClassFN =
    errFN && isSubmit ? "form-control is-invalid" : "form-control pe-5";

  const validClassLN =
    errLN && isSubmit ? "form-control is-invalid" : "form-control pe-5";
  // const showToastMessage = () => toast.success("OTP Sent !");

  const handleClick = (e) => {
    e.preventDefault();
    setFormErrors({});
    let erros = validate(values);
    setFormErrors(erros);
    setIsSubmit(true);
    axios
      .post(
        `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/new/password/users`,
        values
      )
      .then((res) => {
        console.log("res", res.data);
        if (res.data.isSent) {
          toast.success("check your email!");
          setValues(initialState);
          setIsSubmit(false);
          setFormErrors({});
          navigate("/");
        }
        if (res.data.msg === "link is not valid now!") {
          setErrorMsg("Link is expired!");
        }
      })
      .catch((err) => {
        toast.error("Link is not valid now!", {
          position: toast.POSITION.TOP_CENTER,
        });
        console.log(err);
      });
  };

  const [passwordShown, setPasswordShown] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordShown(!passwordShown);
  };

  return (
    <React.Fragment>
      <div>
        <Hader Color="#000" />
        <MobileHeader />
      </div>
      <ToastContainer />
      <Cart />
      <Container>
        <div className="loginDiv">
          <div className="form">
            <Card className="loginCard">
              <span className="h3 text-center mb-3">Change Password</span>
              <form>
                <div className="mb-3 mt-3">
                  <input
                    id="newpassword"
                    className={validClassFN}
                    placeholder="Enter new password"
                    type={passwordShown ? "text" : "password"}
                    required
                    name="newpassword"
                    value={newpassword}
                    style={{ marginBottom: 0 }}
                    onChange={handleChange}
                  />

                  {isSubmit && (
                    <p className="text-danger" style={{ textAlign: "left" }}>
                      {formErrors.firstName}
                    </p>
                  )}
                </div>

                <div className="mb-3" style={{ position: 'relative' }}>
                  <input
                    id="confirmpassword"
                    className={validClassLN}
                    placeholder="Confirm Password"
                    type={passwordShown ? "text" : "password"}
                    required
                    name="confirmpassword"
                    value={confirmpassword}
                    style={{ marginBottom: 0, paddingRight: '30px' }} // Add padding to make space for the icon
                    onChange={handleChange}
                  />
                  <FontAwesomeIcon
                    icon={passwordShown ? faEyeSlash : faEye}
                    onClick={togglePasswordVisibility}
                    style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}
                  />
                  {isSubmit && (
                    <p className="text-danger" style={{ textAlign: "left" }}>
                      {formErrors.lastName}
                    </p>
                  )}
                </div>

                {/* Click on submit button to submit the form */}
                <button
                  style={{ color: "#000" }}
                  onMouseOver={handleMouseOver}
                  onMouseOut={handleMouseOut}
                  onTouchStart={handleTouchStart}
                  onTouchEnd={handleTouchEnd}
                  type="submit" onClick={handleClick}>
                  {isLoading ? (
                    <BarLoader color="#36d7b7" />
                  ) : (
                    "Change Password"
                  )}
                </button>

                {errorMsg && <p style={{ color: "black" }}>{errorMsg}</p>}
              </form>
            </Card>
          </div>
        </div>
      </Container>
    </React.Fragment>
  );
};

export default ChangeNewPasswordPage;
