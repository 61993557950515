import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "./../assets/css/login.css";
import Hader from "../Component/Hader";
import { Card, Container } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

import { OTPUsers, checkRefCode, createUsers } from "../functions/Auth/Users";
import MobileHeader from "../Component/MobileHeader";
import { Label, Input } from "reactstrap";
import { setRef } from "@mui/material";
import { getUsers } from "../functions/Auth/Users";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';



const initialState = {
  firstName: "",
  lastName: "",
  contactNo: "",
  email: "",
  userImage: "",
  password: "",
  IsPublic: false,
  followers: [],
  following: [],
  cart: [],
  defaultShippingAddress: 0,
  defaultBillingAddress: 0,
  shippingAddress: [],
  billingAddress: [],
  referredBy: "",
  points: 0,
  IsActive: true,
};

const SignUp = () => {
  const formdata = new FormData();
  const [values, setValues] = useState(initialState);
  const [isLoading, setIsLoading] = useState(false);

  const handleMouseOver = (event) => {
    event.target.style.color = '#fff';
  };

  const handleMouseOut = (event) => {
    event.target.style.color = '#000';
  };

  const handleTouchStart = (event) => {
    event.target.style.color = '#fff';
  };

  const handleTouchEnd = (event) => {
    event.target.style.color = '#000';
  };


  const navigate = useNavigate();
  const {
    firstName,
    lastName,
    contactNo,
    email,
    userImage,
    password,
    IsPublic,
    followers,
    cart,
    defaultShippingAddress,
    defaultBillingAddress,
    shippingAddress,
    billingAddress,
    following,
    referredBy,
    points,
    IsActive,
  } = values;
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [isRef, setIsRef] = useState(false);
  const [refCode, setRefCode] = useState("");

  const [passwordShown, setPasswordShown] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordShown(!passwordShown);
  };

  const handleChange = (e) => {

   // console.log(e.target.name, e.target.value);

    if (e.target.name === "email") {

      e.target.value = e.target.value.toLowerCase();

    }
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleChangeRef = (e) => {
    setRefCode(e.target.value);
  };

  const handleIsRef = () => {
    setIsRef(!isRef);
  };

  const [errEmail, seterrEmail] = useState(false);
  const [errFN, setErrFN] = useState(false);
  const [errLN, setErrLN] = useState(false);

  const [errPassword, setErrPassword] = useState(false);

  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (values.email === "") {
      errors.email = "Email is required!";
      seterrEmail(true);
    } else if (!regex.test(values.email)) {
      errors.email = "Invalid Email address!";
      seterrEmail(true);
    } else {
      seterrEmail(false);
    }
    if (values.password === "") {
      errors.password = "Password is required!";
      setErrPassword(true);
    }
    if (values.password !== "") {
      setErrPassword(false);
    }

    if (values.firstName === "") {
      errors.firstName = "First Name is required!";
      setErrFN(true);
    }
    if (values.firstName !== "") {
      setErrFN(false);
    }

    if (values.lastName === "") {
      errors.lastName = "Last Name is required!";
      setErrLN(true);
    }
    if (values.lastName !== "") {
      setErrLN(false);
    }
    return errors;
  };
  const validClassEmail =
    errEmail && isSubmit ? "form-control is-invalid" : "form-control";
  const validClassPassword =
    errPassword && isSubmit ? "form-control is-invalid" : "form-control pe-5";
  const validClassFN =
    errFN && isSubmit ? "form-control is-invalid" : "form-control pe-5";
  const validClassLN =
    errLN && isSubmit ? "form-control is-invalid" : "form-control pe-5";

  const handleClick = (e) => {
    e.preventDefault();
    setFormErrors({});
    let erros = validate(values);
    setFormErrors(erros);
    setIsSubmit(true);
    if (Object.keys(erros).length === 0) {
      setIsLoading(true);
      formdata.append("myFile", userImage);
      formdata.append("firstName", firstName);
      formdata.append("lastName", lastName);
      formdata.append("IsActive", IsActive);
      formdata.append("contactNo", contactNo);
      formdata.append("email", email);
      formdata.append("password", password);
      formdata.append("IsPublic", IsPublic);
      formdata.append("followers", followers);
      formdata.append("cart", cart);
      formdata.append("defaultShippingAddress", defaultShippingAddress);
      formdata.append("defaultBillingAddress", defaultBillingAddress);
      formdata.append("shippingAddress", shippingAddress);
      formdata.append("billingAddress", billingAddress);
      formdata.append("following", following);
      formdata.append("referredBy", referredBy);
      formdata.append("points", points);

      const formDataObject = {
        myFile: userImage,
        firstName: firstName,
        lastName: lastName,
        IsActive: IsActive,
        contactNo: contactNo,
        email: email,
        password: password,
        IsPublic: IsPublic,
        followers: followers,
        cart: cart,
        defaultShippingAddress: defaultShippingAddress,
        defaultBillingAddress: defaultBillingAddress,
        shippingAddress: shippingAddress,
        billingAddress: billingAddress,
        following: following,
        referredBy: referredBy,
        points: points,
      };

      OTPUsers(formdata)
        .then((res) => {
          console.log("res", res.data);
          if (res.data.isOk) {
            setValues(initialState);
            setIsSubmit(false);
            setFormErrors({});
            const dataString = encodeURIComponent(JSON.stringify(formdata));
            setIsLoading(false);
            localStorage.setItem("remTime", 900);
            navigate("/verify-otp", { state: { formDataObject } });
          } else {
            if (res.data.message === "Email already exists") {
              setFormErrors({ email: "Email already exists!" });
              setIsLoading(false);
            } else {
              setIsLoading(false);
              seterrEmail(true);
              setFormErrors({ otp: "Invalid OTP!" });
            }
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    }
  };

  const handleCheck = (e) => {
    e.preventDefault();
    checkRefCode(refCode).then((response) => {
      if (response.data.isOk) {
        toast.success("Congratuations! Extra 50 referral points will be credited once the setup is complete.")
        setValues({ ...values, referredBy: response.data.res._id, points: 50 });
      } else {
        setValues({ ...values, referredBy: "", points: 0 });
        toast.error("Referal code is invalid!");
      }
    });
  }

  return (
    <React.Fragment>
      <div>
        <Hader Color="#000" />
        <MobileHeader />
        <ToastContainer />
      </div>
      <Container>
        <div
          className="loginDiv"
          style={{ fontFamily: "Arial, Helvetica, sans-serif" }}
        >
          <Card className="loginCard">
            <h1>Register</h1>

            <form>
              <div className="mb-3">
                <input
                  placeholder="Enter  First Name"
                  type="text"
                  name="firstName"
                  value={firstName}
                  style={{ marginBottom: 0 }}
                  className={validClassFN}
                  onChange={handleChange}
                />
                {isSubmit && (
                  <p className="text-danger" style={{ textAlign: "left" }}>
                    {formErrors.firstName}
                  </p>
                )}
              </div>
              <div className="mb-3">
                <input
                  placeholder="Enter Last Name"
                  type="text"
                  name="lastName"
                  value={lastName}
                  style={{ marginBottom: 0 }}
                  onChange={handleChange}
                  className={validClassLN}
                />
                {isSubmit && (
                  <p className="text-danger" style={{ textAlign: "left" }}>
                    {formErrors.lastName}
                  </p>
                )}
              </div>
              <div className="mb-3">
                <input
                  placeholder="Enter Email"
                  type="email"
                  name="email"
                  value={email}
                  style={{ marginBottom: 0 }}
                  className={validClassEmail}
                  onChange={handleChange}
                />
                {isSubmit && (
                  <p className="text-danger" style={{ textAlign: "left" }}>
                    {formErrors.email}
                  </p>
                )}
              </div>
              <div className="mb-3">
                <input
                  placeholder=" Enter Contact Example : 1112223333"
                  type="tel"
                  name="contactNo"
                  maxLength="10"
                  value={contactNo}
                  style={{ marginBottom: 0 }}
               //   className={validClassEmail}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3" style={{ position: 'relative' }}>
                <input
                  placeholder="Enter Password"
                  name="password"
                  type={passwordShown ? "text" : "password"}
                  value={password}
                  style={{ marginBottom: 0, paddingRight: '30px' }} // Added padding for icon
                  onChange={handleChange}
                  className={validClassPassword}
                />
                <FontAwesomeIcon
                  icon={passwordShown ? faEyeSlash : faEye}
                  onClick={togglePasswordVisibility}
                  style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}
                />
                {isSubmit && (
                  <p className="text-danger" style={{ textAlign: "left" }}>
                    {formErrors.password}
                  </p>
                )}
              </div>
              <p
              //     style={{ fontWeight: "bold", cursor: "pointer" }}
              //     onClick={handleIsRef}
              >
                {
                  //Have a referral code?
                }
              </p>
              {isRef && (
                <div className="mb-3">
                  <input
                    placeholder="Enter code"
                    type="text"
                    name="refCode"
                    value={refCode}
                    onChange={handleChangeRef}
                    style={{ marginBottom: 0 }}
                  />
                  <button className="btn btn-light" onClick={handleCheck} >Check</button>
                </div>
              )}
              <p>
                Already Have An Account?
                <span>
                  <Link className="signUpLink" to="/login">
                    Login
                  </Link>{" "}
                </span>
              </p>

              <button
                style={{ color: "#000" }}
                type="submit"
                onMouseOver={handleMouseOver}
                onMouseOut={handleMouseOut}
                onTouchStart={handleTouchStart}
                onTouchEnd={handleTouchEnd}
                onClick={handleClick}
              >
                Submit
              </button>

            </form>
          </Card>
        </div>
      </Container>
    </React.Fragment>
  );
};

export default SignUp;
