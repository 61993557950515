import React, { useState, useEffect } from "react";
import Hader from "../Component/Hader";
import Products from "../Component/Products";
import Footer from "../Component/Footer";

import LandingModal from "../Component/LandingModal";
import BlogSection from "../Component/BlogSection";

import About from "../Component/About";
import Openhour from "../Component/Openhour";
import Features from "../Component/Features";
import Whatwedo from "../Component/Whatwedo";
import Subscribe from "../Component/Subscribe";
import SlideSection from "../Component/SlideSection";
import Cart from "../Component/Cart";
import MobileHeader from "../Component/MobileHeader";
import LatestProductCarousel from "../Component/LatestProductCarousel";
import Footer2 from "../Component/Footer2";
import { Puff } from "react-loader-spinner";

const Homepage = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const delay = 1000;
    setTimeout(() => {
      setIsLoading(false);
    }, delay);
  }, []);

  return (
    <React.Fragment>
      {isLoading ? (
        <>
          <div className="loader-container">
            <Puff
              color="#c7a17a"
              height={50}
              width={50}
              timeout={0} // 0 means no timeout, loader will be displayed until setIsLoading(false) is called
            />
          </div>
        </>
      ) : (
        <>
          <div>
            <Cart />
            <Hader Color="#000 " />
            <MobileHeader />


              <SlideSection />
              {/* <LandingModal /> */}
              <LatestProductCarousel />
              {/* <About /> */}
              {/* <Products /> */}
              <Whatwedo />
              {/* <Openhour /> */}
              <BlogSection />
              {/* <Openhour /> */}
              {/* <Whatwedo /> */}
              <Features />
              {/* <Subscribe /> */}
              <Footer />
              {/* <Footer2/> */}
            </div>
          </>
      )}
        </React.Fragment>
      );
};

      export default Homepage;
