import React, { useState, useEffect } from "react";
import Hader from "../Component/Hader";
import Footer from "../Component/Footer";
import "./../assets/css/contactus.css";
import BradeCrum from "../Component/BradeCrum";
import Cart from "../Component/Cart";
import axios from "axios";
import MobileHeader from "../Component/MobileHeader";
import { Col, Container, Row } from "react-bootstrap";
import { createInquiry } from "../functions/PolicyAndInquiry/Inquiry";
import { toast, ToastContainer } from "react-toastify";
import { Puff } from "react-loader-spinner";
import { listCompanynDetails } from "../functions/CompanyDetails/CompanyDetails";

const ContactUs = () => {
  const [values, setValues] = useState({
    ContactName: "",
    ContactEmail: "",
    ContactNumber: "",
    Message: "",
  });

  const [companyData, setCompanyData] = useState([]);

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const [isSumit, setIsSumit] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [errFN, setErrFN] = useState(false);
  const [errEM, setErrEM] = useState(false);
  const [errSU, setErrSU] = useState(false);
  const [errMSG, setErrMSG] = useState(false);

  const validate = (values) => {
    const errors = {};
    const phone = /^\d{7,}$/;
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (values.ContactName === "") {
      errors.ContactName = "Contact Name is required!";
      setErrFN(true);
    }
    if (values.ContactName !== "") {
      setErrFN(false);
    }

    if (values.ContactEmail === "") {
      errors.ContactEmail = "Email is required!";
      setErrEM(true);
    } else if (!regex.test(values.ContactEmail)) {
      errors.ContactEmail = "Invalid Email address!";
      setErrEM(true);
    }
    if (values.ContactEmail !== "") {
      setErrEM(false);
    }

    if (values.ContactNumber === "") {
      errors.ContactNumber = "Contact Number is required!";
      setErrSU(true);
    } else if (!phone.test(values.ContactNumber)) {
      errors.ContactNumber = "This is not a valid Contact Number";
      setErrSU(true);
    }
    if (values.ContactNumber !== "") {
      setErrSU(false);
    }

    if (values.Message == "") {
      errors.Message = "Message is required!";
      setErrMSG(true);
    }
    if (values.Message !== "") {
      setErrMSG(false);
    }

    return errors;
  };

  const validClassName =
    errFN && isSumit ? "form-control is-invalid" : "form-control";
  const validClassEmail =
    errEM && isSumit ? "form-control is-invalid" : "form-control";
  const validClassSubject =
    errSU && isSumit ? "form-control is-invalid" : "form-control";

  const validClassMessage =
    errMSG && isSumit ? "form-control is-invalid" : "form-control";

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSumit(true);

    let erros = validate(values);
    setFormErrors(erros);
    if (Object.keys(erros).length === 0) {
      createInquiry(values).then((res) => {
        console.log("res in createInquiry", res.data);
        // if (res.data.isOk) {
        toast.success("Message sent successfully");
        // setValues(initialState);
        setValues({
          ContactName: "",
          ContactEmail: "",
          ContactNumber: "",
          Message: "",
        });
        // }
      });
    }
  };

  // const handleAdd = () => {
  //   axios
  //     .post(
  //       `${process.env.REACT_APP_API_URL_COFFEE}/api/auth/create/Inquiry`,
  //       values
  //     )
  //     .then((res) => console.log(res))
  //     .catch((err) => console.log(err));
  //   setValues({
  //     ContactName: "",
  //     ContactEmail: "",
  //     ContactNumber: "",
  //     Message: "",
  //   });
  // };
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const delay = 1000;
    setTimeout(() => {
      setIsLoading(false);
    }, delay);
  }, []);

  useEffect(() => {
    loadCompanyData();
  }, []);

  const loadCompanyData = () => {
    listCompanynDetails().then((res) => {
      console.log("res company", res.data);
      setCompanyData(res.data);
    });
  };

  return (
    <React.Fragment>
      {isLoading ? (
        // Loader component while loading
        <div className="loader-container">
          <Puff
            color="#c7a17a"
            height={50}
            width={50}
            timeout={0} // 0 means no timeout, loader will be displayed until setIsLoading(false) is called
          />
        </div>
      ) : (
        <>
          <Cart />
          <Hader Color="#000" />
          <MobileHeader />
          <BradeCrum Title="Contact Us" />
          <section className="contact_section sec_ptb_120 bg_default_gray">
            <div className="container">
              <div
                className="contact_form bg_white wow fadeInUp"
                data-wow-delay=".1s"
              >
                <div className="main_contact_info_wrap">
                  <Container>
                    <h3
                      className="big_title wow fadeInUp mb-4"
                      data-wow-delay=".2s"
                    >
                      CONTACT US
                    </h3>
                    <Row>
                      <Col lg={4} md={6} sm={12}>
                        <div className="contact_info_item">
                          <div className="item_icon">
                            <svg
                              stroke="currentColor"
                              fill="currentColor"
                              strokeWidth={0}
                              viewBox="0 0 24 24"
                              height="1em"
                              width="1em"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g id="Mail">
                                <path d="M19.435,4.065H4.565a2.5,2.5,0,0,0-2.5,2.5v10.87a2.5,2.5,0,0,0,2.5,2.5h14.87a2.5,2.5,0,0,0,2.5-2.5V6.565A2.5,2.5,0,0,0,19.435,4.065Zm-14.87,1h14.87a1.489,1.489,0,0,1,1.49,1.39c-2.47,1.32-4.95,2.63-7.43,3.95a6.172,6.172,0,0,1-1.06.53,2.083,2.083,0,0,1-1.67-.39c-1.42-.75-2.84-1.51-4.25-2.26-1.14-.6-2.3-1.21-3.44-1.82A1.491,1.491,0,0,1,4.565,5.065Zm16.37,12.37a1.5,1.5,0,0,1-1.5,1.5H4.565a1.5,1.5,0,0,1-1.5-1.5V7.6c2.36,1.24,4.71,2.5,7.07,3.75a5.622,5.622,0,0,0,1.35.6,2.872,2.872,0,0,0,2-.41c1.45-.76,2.89-1.53,4.34-2.29,1.04-.56,2.07-1.1,3.11-1.65Z" />
                              </g>
                            </svg>
                          </div>
                          <div className="item_content">
                            <h3 className="item_title text-uppercase">
                              Email Adress
                            </h3>
                            {/* <p>Info@nicksroastingco.com</p> */}
                            <p>
                              {companyData.map((c) => {
                                return (
                                  c.IsActive && (
                                    <React.Fragment key={c._id}>
                                      <>
                                        {/* <CiMail /> */}
                                        <a
                                          href={`mailto:${c.EmailID_Office}`}
                                          style={{ color: "black !important" }}
                                          className="text-dark"
                                        >
                                          {" "}
                                          {c.EmailID_Office}
                                        </a>
                                      </>
                                    </React.Fragment>
                                  )
                                );
                              })}
                            </p>
                          </div>
                        </div>
                      </Col>
                      <Col lg={4} md={6} sm={12}>
                        <div className="contact_info_item">
                          <div className="item_icon">
                            <svg
                              stroke="currentColor"
                              fill="currentColor"
                              strokeWidth={0}
                              viewBox="0 0 24 24"
                              height="1em"
                              width="1em"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g id="Location_On">
                                <g>
                                  <path d="M12,21.933a1.715,1.715,0,0,1-1.384-.691L5.555,14.5a7.894,7.894,0,1,1,12.885-.009L13.385,21.24A1.717,1.717,0,0,1,12,21.933ZM11.992,3.066A6.81,6.81,0,0,0,7.414,4.815a6.891,6.891,0,0,0-1.05,9.1l5.051,6.727a.725.725,0,0,0,.584.292h0a.732.732,0,0,0,.586-.292l5.044-6.734A6.874,6.874,0,0,0,12.81,3.113,7.277,7.277,0,0,0,11.992,3.066Z" />
                                  <path d="M12,12.5A2.5,2.5,0,1,1,14.5,10,2.5,2.5,0,0,1,12,12.5Zm0-4A1.5,1.5,0,1,0,13.5,10,1.5,1.5,0,0,0,12,8.5Z" />
                                </g>
                              </g>
                            </svg>
                          </div>
                          <div className="item_content">
                            <h3 className="item_title text-uppercase">
                              Our Location
                            </h3>
                            {/* <p>2558 Greenwood Ln, 120 Cameron Park, CA 95682</p> */}
                            <p>
                              {companyData.map((c) => {
                                return (
                                  c.IsActive && (
                                    <React.Fragment key={c._id}>
                                      <p style={{ width: "230px" }}>
                                        {c.Address}
                                      </p>
                                    </React.Fragment>
                                  )
                                );
                              })}
                            </p>
                          </div>
                        </div>
                      </Col>
                      <Col lg={4} md={6} sm={12}>
                        <div className="contact_info_item">
                          <div className="item_icon">
                            <svg
                              stroke="currentColor"
                              fill="currentColor"
                              strokeWidth={0}
                              viewBox="0 0 24 24"
                              height="1em"
                              width="1em"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g id="Phone">
                                <path d="M14.436,20.938A11.384,11.384,0,0,1,4.572,3.9a1.668,1.668,0,0,1,1.241-.822,1.716,1.716,0,0,1,1.454.492l3.139,3.14a1.715,1.715,0,0,1,0,2.427l-.295.3a1.937,1.937,0,0,0,0,2.736l1.72,1.721a1.983,1.983,0,0,0,2.736,0l.29-.29a1.719,1.719,0,0,1,2.428,0l3.139,3.139a1.724,1.724,0,0,1,.492,1.455,1.669,1.669,0,0,1-.822,1.239A11.327,11.327,0,0,1,14.436,20.938ZM6.042,4.063a.793.793,0,0,0-.1.006.673.673,0,0,0-.5.331A10.375,10.375,0,0,0,19.594,18.567a.674.674,0,0,0,.331-.5.734.734,0,0,0-.208-.618l-3.139-3.139a.717.717,0,0,0-1.014,0l-.29.29a3.006,3.006,0,0,1-4.15,0L9.4,12.876a2.939,2.939,0,0,1,0-4.149l.3-.3a.717.717,0,0,0,0-1.014L6.56,4.277A.729.729,0,0,0,6.042,4.063Z" />
                              </g>
                            </svg>
                          </div>
                          <div className="item_content">
                            <h3 className="item_title text-uppercase">
                              Phone Number
                            </h3>
                            {/* <p>1800 297 4001</p> */}
                            <p>
                              {" "}
                              {companyData.map((c) => {
                                return (
                                  c.IsActive && (
                                    <React.Fragment>
                                      {/* <CiPhone /> */}
                                      <a
                                        //href={`tel:+${c.ContactNo_Office}`}
                                        style={{ color: "black important" }}
                                        className="text-dark"
                                      >
                                        {c.ContactNo_Office}
                                      </a>
                                    </React.Fragment>
                                  )
                                );
                              })}
                            </p>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </div>
                {/* <form action="#"> */}
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form_item">
                      <input
                        type="text"
                        className={validClassName}
                        name="ContactName"
                        placeholder="Your name:"
                        onChange={(e) => handleChange(e)}
                        value={values.ContactName}
                      />
                      {isSumit && (
                        <p className="text-danger">{formErrors.ContactName}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form_item">
                      <input
                        type="email"
                        className={validClassEmail}
                        name="ContactEmail"
                        placeholder="Your Mail:"
                        onChange={(e) => handleChange(e)}
                        value={values.ContactEmail}
                      />
                      {isSumit && (
                        <p className="text-danger">{formErrors.ContactEmail}</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="form_item">
                  <input
                    type="number"
                    name="ContactNumber"
                    className={validClassSubject}
                    placeholder="Contact Number:"
                    onChange={(e) => handleChange(e)}
                    value={values.ContactNumber}
                  />
                  {isSumit && (
                    <p className="text-danger">{formErrors.ContactNumber}</p>
                  )}
                </div>

                <div className="form_item">
                  <textarea
                    name="Message"
                    className={validClassMessage}
                    placeholder="Your Message:"
                    defaultValue={""}
                    rows={4}
                    onChange={(e) => handleChange(e)}
                    value={values.Message}
                  />
                  {isSumit && (
                    <p className="text-danger">{formErrors.Message}</p>
                  )}
                </div>
                <div className="text-center">
                  <button
                    type="submit"
                    className="btn btn_primary text-uppercase"
                    onClick={handleSubmit}
                  >
                    Send Message
                  </button>
                </div>
                {/* </form> */}
              </div>
              <div style={{ backgroundColor: "grey", padding: "10px", textAlign: "center", color: "white" }}>
                <h3 >
                  Nick's Roasting Co
                </h3>
                <h3>
                  No. 9162947749
                </h3>
              </div>
              <iframe
                src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=8700%20Auburn%20Folsom%20Rd%20Ste%20700+(Nick's%20Roasting%20Co.)&amp;t=&amp;z=17&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                width="100%"
                height="450"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </section>
          <Footer />
        </>
      )}
    </React.Fragment>
  );
};

export default ContactUs;
